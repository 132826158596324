import React, { useState } from "react";
import classNames from "classnames";
import { Button as MuiButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { buttonStyles as styles } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronDownIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { MenuOptionList } from "../popoverMenu/menu-option-list.component";
import { iconTypes } from "../popoverMenu/types";

const supportedIcons = (({ [iconTypes.kebab]: _, ...o }) => o)(iconTypes);

const propTypes = {
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.shape({ index: PropTypes.number, name: PropTypes.string })),
  onClickButton: PropTypes.func,
  onClickMenu: PropTypes.func,
  defaultTitle: PropTypes.string,
  icon: PropTypes.node,
  iconType: PropTypes.oneOf(Object.values(supportedIcons)),
  rootClassName: PropTypes.string,
};

const useStyles = makeStyles(() => styles);

const SplitButton = ({
  onClickButton = (_event) => {},
  onClickMenu = (_event, _index) => {},
  menuItems = [],
  ariaLabel = "",
  disabled = false,
  defaultTitle = null,
  icon = null,
  iconType = iconTypes.arrow,
  rootClassName = null,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const classes = useStyles();
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div className={classes.container}>
      <div
        className={classNames(
          "gx-split-button",
          classes.root,
          disabled && "gx-split-button-disabled",
          "gx-split-button-rounded",
          rootClassName
        )}
      >
        <MuiButton
          aria-label={ariaLabel || null}
          className={classNames("gx-button", "btn-secondary", classes.root, "gx-split-button-flat")}
          disabled={disabled}
          onClick={onClickButton}
        >
          {!!icon && <span className="gx-button-icon">{icon}</span>}
          {defaultTitle}
        </MuiButton>
        <div className={classNames("gx-divider", classes.root, disabled && "gx-divider-disabled")} />
        <MuiButton
          aria-label={ariaLabel || null}
          className={classNames("gx-button-split", "btn-secondary-split", classes.root, "gx-split-button-flat")}
          disabled={disabled}
          onClick={toggleDropdown}
        >
          {iconType === iconTypes.arrowDynamic ? (
            <span
              aria-label={dropdownOpen ? "close menu" : "open menu"}
              className={classNames("gx-button-drop-down-icon", dropdownOpen ? "arrow-up" : "arrow-down")}
            >
              <ChevronDownIcon />
            </span>
          ) : (
            <ChevronDownIcon />
          )}
        </MuiButton>
      </div>
      {dropdownOpen && (
        <MenuOptionList
          menuItems={menuItems}
          onNonOptionsClick={toggleDropdown}
          onOptionsClick={(index) => {
            handleEvent(onClickMenu, index);
            toggleDropdown();
          }}
          containerClass="gx-split-dropdown"
        />
      )}
    </div>
  );
};
SplitButton.propTypes = propTypes;
export { SplitButton };
