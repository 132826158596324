import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { SavedCloudIcon, SavingSpinnerIcon } from "gx-npm-icons";
import { saveIndicatorStyles } from "./styles";
import { TypographyComponent } from "../typography/typography.component";

const propTypes = {
  isError: PropTypes.bool,
  isSaving: PropTypes.bool,
  labelSaved: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelSaving: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rootClassName: PropTypes.string,
};
/**
 isError flag for if an error occured
 isSaving flag for when saving is occuring
 labelSaved text label to show when save is successful
 labelSaving text label to show while saving
 rootClassName class name to use for overriding styling
 */

const useStyles = makeStyles(() => saveIndicatorStyles);

const SaveIndicator = ({
  isError = false,
  isSaving = false,
  labelSaved = "Changes saved",
  labelSaving = "Saving...",
  rootClassName = "",
}) => {
  const isSpinning = isSaving || isError;
  const classes = useStyles();
  return (
    <div className={classNames("gx-save-indicator-root", classes.indicatorRoot, rootClassName)}>
      <TypographyComponent color={"iron"} styling={"p3"} boldness={"medium"}>
        {isSpinning ? labelSaving : labelSaved}
      </TypographyComponent>

      <div className={classNames("save-indicator-icon", classes.indicatorIcon)}>
        <span className={classNames(isSpinning && classes.spinIcon)}>
          {isSpinning ? <SavingSpinnerIcon /> : <SavedCloudIcon />}
        </span>
      </div>
    </div>
  );
};

SaveIndicator.propTypes = propTypes;
export { SaveIndicator };
