// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aZ00bDJ2pCq4cqyqf5cw{margin-left:auto;margin-right:24px;width:115px}.lrWE5K55H5V_t57myho2{align-items:center;display:flex}.lrWE5K55H5V_t57myho2 svg{margin-right:8px}.Ek8xhLXaKlIoPqAY8PqB{align-self:flex-start;margin-left:4px}`, "",{"version":3,"sources":["webpack://./src/sections/market-products/product-row/cells/peer-insights-cell/peer-insights-cell.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,iBAAA,CACA,WAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CAEA,0BACE,gBAAA,CAIJ,sBACE,qBAAA,CACA,eAAA","sourcesContent":[".root {\n  margin-left: auto;\n  margin-right: 24px;\n  width: 115px;\n}\n\n.piRating {\n  align-items: center;\n  display: flex;\n\n  svg {\n    margin-right: 8px;\n  }\n}\n\n.piDenominator {\n  align-self: flex-start;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `aZ00bDJ2pCq4cqyqf5cw`,
	"piRating": `lrWE5K55H5V_t57myho2`,
	"piDenominator": `Ek8xhLXaKlIoPqAY8PqB`
};
export default ___CSS_LOADER_EXPORT___;
