import { colorPalette } from "gx-npm-common-styles";
import { Paragraph } from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import MarketFeaturesTableHeaderComponent from "../market-features-table-header.component";
import styles from "./market-features-unentitled-table.styles.module.scss";
import UnentitledAlertIcon from "./unentitled-alert.icon";

const MarketFeaturesUnentitledTableComponent = () => {
  const { t } = useTranslation();
  const rowLabel = t("insights only available for Gartner licensed users");
  return (
    <Fragment>
      <div className={styles.unentitledTitleContainer}>
        <UnentitledAlertIcon />
        <Paragraph boldness="medium" rootClassName={styles.unentitledTitleText} type="p3">
          {t(
            "This insight is only available for Gartner licensed users, to access features and functionality please contact your Gartner Account Manager."
          )}
        </Paragraph>
      </div>
      <table cellSpacing="0" className={styles.unentitledTableContainer}>
        <MarketFeaturesTableHeaderComponent />
        <tbody className={styles.unentitledTableBody}>
          <tr className={styles.unentitledTableRow}>
            <td aria-label={rowLabel}>
              <svg width="190" height="11" viewBox="0 0 190 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="190" height="11" fill={colorPalette.neutrals.silver.hex} />
              </svg>
            </td>
            <td aria-label={rowLabel}>
              <svg
                className={styles.unentitledProductIcon}
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
              </svg>
              <svg
                className={styles.unentitledSpacedBar}
                width="84"
                height="11"
                viewBox="0 0 84 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="84" height="11" fill={colorPalette.neutrals.silver.hex} />
              </svg>
              <svg width="84" height="11" viewBox="0 0 84 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="84" height="11" fill={colorPalette.neutrals.silver.hex} />
              </svg>
            </td>
          </tr>
          <tr className={styles.unentitledTableRow}>
            <td aria-label={rowLabel}>
              <svg width="234" height="11" viewBox="0 0 234 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="234" height="11" fill={colorPalette.neutrals.silver.hex} />
              </svg>
            </td>
            <td aria-label={rowLabel}>
              <svg
                className={styles.unentitledProductIcon}
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
              </svg>
              <svg width="103" height="11" viewBox="0 0 103 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="103" height="11" fill={colorPalette.neutrals.silver.hex} />
              </svg>
            </td>
          </tr>
          <tr className={styles.unentitledTableRow}>
            <td aria-label={rowLabel}>
              <svg width="190" height="11" viewBox="0 0 190 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="190" height="11" fill={colorPalette.neutrals.silver.hex} />
              </svg>
            </td>
            <td aria-label={rowLabel}>
              <svg
                className={styles.unentitledProductIcon}
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
              </svg>
              <svg width="190" height="11" viewBox="0 0 190 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="190" height="11" fill={colorPalette.neutrals.silver.hex} />
              </svg>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default MarketFeaturesUnentitledTableComponent;
