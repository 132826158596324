import classNames from "classnames";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { MarketGuideIcon } from "gx-npm-icons";
import classes from "./market-guide-body.styles.module.scss";
import { colorPalette } from "gx-npm-common-styles";

const MarketGuideBody = ({ templateName = "", documentQuery = -1 }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.contentBodyMidSectionLeft)}>
      <TypographyComponent
        styling={"h5"}
        rootClassName={classNames(classes.contentBodyMidSectionTitleText)}
        color={"berry"}
      >
        <MarketGuideIcon fillPath={colorPalette.interactions.berry.hex} />
        {t("Market Guide")}
      </TypographyComponent>
      <TypographyComponent rootClassName={classes.contentBodyMidSectionDescTextGCOM3523} color="quartz" styling="p2">
        {t(
          "Market Guides (MGs) deliver a clear definition and framing of the market, market dynamics and an overview of market participants. They deliver leaders who are considering an investment with actionable insights on use cases spurring the market, offerings and market direction."
        )}
      </TypographyComponent>
      <div className={classNames(classes.contentLearnMoreLine)} />
      <TypographyComponent
        element={"p"}
        rootClassName={classNames(classes.contentBodyMidSectionLearnMore)}
        color={"stone"}
      >
        {t("Learn more")}
      </TypographyComponent>
      <div className={classNames(classes.contentLink)}>
        <TextLink
          rootClassName={classNames(classes.contentBodyMidSectionLinkText)}
          href={`https://www.gartner.com/document/${documentQuery}`}
          target="_blank"
          text={t(`Market Guide for ${templateName}`)}
          variant={"dark"}
        />
      </div>
    </div>
  );
};

export default MarketGuideBody;
