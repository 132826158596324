import { colorPalette } from "gx-npm-common-styles";

const carbonHex = colorPalette.neutrals.carbon.hex;
const ironHex = colorPalette.neutrals.iron.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const cherryHex = colorPalette.status.cherry.hex;

const selectStyles = ({ menuHeight }) => {
  return {
    formControl: {
      "&.bs-select-form-control": {
        minWidth: 120,
        "& .bs-select-label": {
          top: -5,
          "&.Mui-focused": {
            color: defaultCta,
            top: "0px",
          },
        },
        "& .bs-select-container": {
          color: carbonHex,
          height: 48,
          "&.Mui-focused": {
            "& fieldset": {
              borderColor: defaultCta,
              borderWidth: 2,
            },
          },
          "& .bs-select-menu": {
            backgroundColor: "white",
            fontSize: 16,
            letterSpacing: "0.25px",
            lineHeight: "24px",
          },
        },
        "& svg": {
          cursor: "pointer",
          marginRight: 16,
          pointerEvents: "none",
          position: "absolute",
          right: 0,
          transform: "none",
        },
        "&.disabled": {
          "& .bs-select-container": {
            color: ironHex,
            "& fieldset": {
              border: "1px dashed",
              borderColor: ironHex,
            },
          },
          "& .bs-select-label": {
            color: ironHex,
            "&:not(.Mui-focused)": {
              top: -5,
            },
          },
          "& svg": {
            "& path": {
              fill: colorPalette.neutrals.stone.hex,
            },
          },
        },
        "&.selected": {
          "& .bs-select-label": {
            "&:not(.Mui-focused)": {
              top: 0,
            },
          },
        },
        "&.disabled.selected": {
          "& .bs-select-label": {
            "&:not(.Mui-focused)": {
              top: 0,
            },
          },
        },
        "& .red-asterisk": {
          color: cherryHex,
        },
      },
    },
    menu: {
      height: menuHeight,
      padding: 0,
      "& li": {
        color: carbonHex,
        fontSize: 14,
        letterSpacing: "0.25px",
        lineHeight: "21px",
        paddingBottom: 7,
        "&:last-of-type": {
          paddingBottom: 10,
          paddingTop: 9,
        },
      },
    },
    menuPaper: {
      border: `2px solid ${colorPalette.neutrals.silver.hex}`,
      boxShadow: "none",
      marginLeft: -1,
      marginTop: -7,
    },
  };
};

export { selectStyles };
