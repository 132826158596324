import { UUID } from "gx-npm-lib";
import { createContext, ReactNode, useState } from "react";
import { FeatureAndFunctionality, MarketProfileAppContextValue, GartnerDocument, MarketProduct } from "./app.types";

const MarketProfileAppContext = createContext<MarketProfileAppContextValue>({} as MarketProfileAppContextValue);
const MarketProfileAppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [checklistCount, setChecklistCount] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [featuresAndFunctionalities, setFeaturesAndFunctionalities] = useState<FeatureAndFunctionality[]>([]);
  const [gcomCcDocCd, setGcomCcDocCd] = useState<number>(-1);
  const [gcomCcResId, setGcomCcResId] = useState<number>(-1);
  const [gcomMgResId, setGcomMgResId] = useState<number>(-1);
  const [gcomMqResId, setGcomMqResId] = useState<number>(-1);
  const [hasSelectedPriorities, setHasSelectedPriorities] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isProductListLoading, setIsProductListLoading] = useState<boolean>(false);
  const [isWeightedPriorityDrawerOpen, setIsWeightedPriorityDrawerOpen] = useState<boolean>(false);
  const [latestExistingInitId, setLatestExistingInitId] = useState<UUID | "">("");
  const [moreFromGartner, setMoreFromGartner] = useState<GartnerDocument[]>([]);
  const [name, setName] = useState<string>("");
  const [piMarketSeoName, setPiMarketSeoName] = useState<string>("");
  const [productCount, setProductCount] = useState<number>(0);
  const [products, setProducts] = useState<MarketProduct[]>([]);
  const [requirementCount, setRequirementCount] = useState<number>(0);
  const [templateId, setTemplateId] = useState<number>(0);
  const [userInitCount, setUserInitCount] = useState<number>(0);

  const contextValue = {
    checklistCount,
    description,
    featuresAndFunctionalities,
    gcomCcDocCd,
    gcomCcResId,
    gcomMgResId,
    gcomMqResId,
    hasSelectedPriorities,
    isLoading,
    isProcessing,
    isProductListLoading,
    isWeightedPriorityDrawerOpen,
    latestExistingInitId,
    moreFromGartner,
    name,
    piMarketSeoName,
    productCount,
    products,
    requirementCount,
    templateId,
    userInitCount,
    setChecklistCount,
    setDescription,
    setFeaturesAndFunctionalities,
    setGcomCcDocCd,
    setGcomCcResId,
    setGcomMgResId,
    setGcomMqResId,
    setHasSelectedPriorities,
    setIsLoading,
    setIsProcessing,
    setIsProductListLoading,
    setIsWeightedPriorityDrawerOpen,
    setLatestExistingInitId,
    setMoreFromGartner,
    setName,
    setPiMarketSeoName,
    setProductCount,
    setProducts,
    setRequirementCount,
    setTemplateId,
    setUserInitCount,
  };
  return <MarketProfileAppContext.Provider value={contextValue}>{props.children}</MarketProfileAppContext.Provider>;
};

export { MarketProfileAppContext, MarketProfileAppContextProvider };
