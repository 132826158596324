import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ArrowMoreIcon } from "gx-npm-icons";
import { makeStyles } from "@material-ui/core/styles";
import { textLinkStyles } from "./styles";
import { handleEvent } from "gx-npm-lib";
import { TypographyComponent } from "../typography/typography.component";

const variantType = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  dark: "dark",
};
const propTypes = {
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(variantType)),
};
const useStyles = makeStyles(() => textLinkStyles);
const TextLink = ({
  ariaLabel = "",
  disabled = false,
  href = "",
  onClick = (..._args) => {},
  rootClassName = "",
  target = "",
  text = "",
  variant = variantType.primary,
}) => {
  const classes = useStyles();

  return (
    <a
      aria-label={ariaLabel || null}
      disabled={disabled}
      className={classNames(
        "gx-text-link",
        rootClassName,
        classes.textLink,
        disabled && "disabled",
        variantType[variant] ? variant : variantType.primary
      )}
      href={href || null}
      onClick={(e) => handleEvent(onClick, e)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleEvent(onClick, e);
        }
      }}
      rel={target === "_blank" ? "noreferrer" : null}
      target={target || null}
      tabIndex={0}
    >
      <TypographyComponent styling={variant === variantType.secondary ? "p4" : "p3"}>{text}</TypographyComponent>
      {variant === variantType.secondary && <ArrowMoreIcon />}
    </a>
  );
};

TextLink.propTypes = propTypes;
export { TextLink, variantType as variantTypeTextLink };
