import { useTranslation } from "react-i18next";
import React, { Fragment, useContext } from "react";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./more-from-gartner.styles.module.scss";
import { MarketProfileAppContext } from "../../app.context";
import noArticleFromGartner from "../../assets/images/no-article-from-gartner.svg";
import classNames from "classnames";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../app.constants";

const MoreFromGartnerComponent = () => {
  const { t } = useTranslation();
  const { moreFromGartner, name, templateId } = useContext(MarketProfileAppContext);
  const captureEvents = useCaptureEventsV2();
  const discoverMoreURL = `https://www.gartner.com/mysearch/research/adv?${new URLSearchParams({
    termsIn: "0",
    researchStatus: "1",
    entitlement: "1",
    start: "0",
    contentType: "41,26,61,5,62,6,7,14,4,39,46,63,18,10",
    q: name,
  })}`;
  const handleSearchResultClick = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    const metaData = { templateId: templateId.toString(), templateName: name };
    captureEvents([{ eventType: ClientEvent.MARKET_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED, metaData }]);
    window.open(url, "_blank", "noopener");
  };

  return (
    <section className={classNames(styles.section)}>
      <div className={classNames(styles.card)}>
        <TypographyComponent boldness={"medium"} styling={"p1"} rootClassName={styles.label}>
          {t("More from Gartner")}
        </TypographyComponent>

        {moreFromGartner.length > 0 && (
          <Fragment>
            <div className={styles.results}>
              {moreFromGartner.map((item) => {
                const { title, summary, date, url } = item;
                return (
                  <div className={styles.result} key={title}>
                    <div>
                      <TextLink
                        href={url}
                        onClick={(event) => handleSearchResultClick(event, url)}
                        target={"_blank"}
                        text={title}
                      />
                    </div>
                    <div>
                      <TypographyComponent styling={"p4"} boldness={"medium"} rootClassName={styles.date}>
                        {date}
                      </TypographyComponent>
                      <TypographyComponent styling={"p4"} boldness={"regular"} rootClassName={styles.summary}>
                        {summary}
                      </TypographyComponent>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.divider} />
            <TextLink
              href={discoverMoreURL}
              onClick={(event) => handleSearchResultClick(event, discoverMoreURL)}
              target={"_blank"}
              text={t("Discover more on Gartner.com")}
              variant="secondary"
            />
          </Fragment>
        )}
        {moreFromGartner.length === 0 && (
          <div className={styles.noArticles}>
            <img src={noArticleFromGartner} alt={t("no article from Gartner")} />
            <TypographyComponent styling="p3" boldness="regular" color={"iron"}>
              {t("No articles were found.")}
            </TypographyComponent>
          </div>
        )}
      </div>
    </section>
  );
};

export default MoreFromGartnerComponent;
