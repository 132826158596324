// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nKcg93sZHRFzXe3NK3eK{position:absolute;margin-top:-121px;padding:2px 12px;width:180px;height:24px;background-color:rgba(0,40,86,.1);border-width:0;border-bottom-right-radius:8px}.nKcg93sZHRFzXe3NK3eK:hover{background-color:rgba(0,40,86,.2);cursor:pointer}.nKcg93sZHRFzXe3NK3eK .Kq50MbWrOiDQCYYyp07p{font-size:16px;margin-top:-1px}.nKcg93sZHRFzXe3NK3eK .Kq50MbWrOiDQCYYyp07p path{fill:var(--color-brand-gartner-blue)}.nKcg93sZHRFzXe3NK3eK .K6EGpSrcXQw55xqqqOJ7{margin-top:-2px;padding-left:8px;color:var(--color-brand-gartner-blue);white-space:nowrap}.zzwFbi1ULgrbilpZLEdP{display:flex;justify-content:space-between;align-items:center}`, "",{"version":3,"sources":["webpack://./src/sections/market-products/product-row/proposal-review-badge/proposal-review-badge.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,iBAAA,CACA,gBAAA,CACA,WAAA,CACA,WAAA,CACA,iCAAA,CACA,cAAA,CACA,8BAAA,CAEA,4BACE,iCAAA,CACA,cAAA,CAGF,4CACE,cAAA,CACA,eAAA,CAEA,iDACE,oCAAA,CAIJ,4CACE,eAAA,CACA,gBAAA,CACA,qCAAA,CACA,kBAAA,CAKJ,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".root {\n  position: absolute;\n  margin-top: -121px;\n  padding: 2px 12px;\n  width: 180px;\n  height: 24px;\n  background-color: rgba(0, 40, 86, 0.1);\n  border-width: 0;\n  border-bottom-right-radius: 8px;\n\n  &:hover {\n    background-color: rgba(0, 40, 86, 0.2);\n    cursor: pointer;\n  }\n\n  .iconContainer {\n    font-size: 16px;\n    margin-top: -1px;\n\n    path {\n      fill: var(--color-brand-gartner-blue);\n    }\n  }\n\n  .textContainer {\n    margin-top: -2px;\n    padding-left: 8px;\n    color: var(--color-brand-gartner-blue);\n    white-space: nowrap;\n  }\n\n}\n\n.richTooltipContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nKcg93sZHRFzXe3NK3eK`,
	"iconContainer": `Kq50MbWrOiDQCYYyp07p`,
	"textContainer": `K6EGpSrcXQw55xqqqOJ7`,
	"richTooltipContainer": `zzwFbi1ULgrbilpZLEdP`
};
export default ___CSS_LOADER_EXPORT___;
