import classNames from "classnames";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { buttonStyles as styles } from "./styles";

const propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.any, // ReactNode | ReactNode[] | string
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const Button = ({
  ariaLabel = "",
  children,
  disabled = false,
  href = "",
  onClick = (..._args) => {},
  rootClassName = "btn-tertiary",
}) => {
  const classes = useStyles();
  return (
    <MuiButton
      aria-label={ariaLabel || null}
      className={classNames("gx-button", rootClassName, classes.root, "gx-button-rounded")}
      color="primary"
      disabled={disabled}
      disableElevation={true}
      href={href || null}
      onClick={onClick}
      variant="text"
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = propTypes;
export { Button };
