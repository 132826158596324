import React from "react";
import { IconProps } from "../types";

const xmlnsUrl = "http://www.w3.org/2000/svg";

export const IconMyInitiatives = ({
  title = "",
  fillSvg = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C6 2.44772 6.44772 2 7 2H19C19.5523 2 20 2.44772 20 3V18C20 18.5523 19.5523 19 19 19H7C6.44772 19 6 18.5523 6 18V3ZM10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7H16C16.5523 7 17 6.55228 17 6C17 5.44772 16.5523 5 16 5H10ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM3.5 3C3.5 2.72386 3.27614 2.5 3 2.5C2.72386 2.5 2.5 2.72386 2.5 3V21C2.5 21.8284 3.17157 22.5 4 22.5H18C18.2761 22.5 18.5 22.2761 18.5 22C18.5 21.7239 18.2761 21.5 18 21.5H4C3.72386 21.5 3.5 21.2761 3.5 21V3Z"
        fill="white"
      />
    </svg>
  );
};

export const IconTemplates = ({ title = "", fillSvg = "none", height = "24", viewBox = "0 0 24 24", width = "24" }) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 4.44772 4.44772 4 5 4H10C10.5523 4 11 4.44772 11 5V19C11 19.5523 10.5523 20 10 20H5C4.44772 20 4 19.5523 4 19V5ZM13 5C13 4.44772 13.4477 4 14 4H19C19.5523 4 20 4.44772 20 5V7C20 7.55228 19.5523 8 19 8H14C13.4477 8 13 7.55228 13 7V5ZM14 10C13.4477 10 13 10.4477 13 11V19C13 19.5523 13.4477 20 14 20H19C19.5523 20 20 19.5523 20 19V11C20 10.4477 19.5523 10 19 10H14Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export const IconCurrentInitiative = ({
  fill = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
  title = "",
}: IconProps) => {
  const xmlns = "http://www.w3.org/2000/svg";
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C5.44772 3 5 3.44772 5 4V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V4C19 3.44772 18.5523 3 18 3H6ZM8 14.66V16.5C8 16.7761 8.22386 17 8.5 17H10.3375L14.8194 12.5265L12.4741 10.1812L8 14.66ZM16.858 10.4916L15.5271 11.82L13.1808 9.47372L14.511 8.14211C14.7003 7.95263 14.9937 7.95263 15.183 8.14211L16.858 9.81895C17.0473 10.0084 17.0473 10.3021 16.858 10.4916Z"
        fill="white"
      />
    </svg>
  );
};

export const IconGetStarted = ({
  fill = "#0052D6",
  height = "56",
  viewBox = "0 0 56 56",
  width = "56",
  title = "",
}: IconProps) => {
  const xmlns = "http://www.w3.org/2000/svg";
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <rect width={width} height={height} rx="28" fill={fill} />
      <path d="M28 20L26.59 21.41L32.17 27H20V29H32.17L26.59 34.59L28 36L36 28L28 20Z" fill="white" />
    </svg>
  );
};

export const IconMyInitiativesV2 = ({
  fill = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
  title = "",
  fillColor = "#BBB9B8",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <rect width={width} height={height} rx="28" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13C3.44772 13 3 13.4477 3 14V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V14C21 13.4477 20.5523 13 20 13H4ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H11.2857C11.838 18 12.2857 17.5523 12.2857 17C12.2857 16.4477 11.838 16 11.2857 16H7Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C3.44772 3 3 3.44772 3 4V10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10V4C21 3.44772 20.5523 3 20 3H4ZM6 7C6 6.44772 6.44772 6 7 6H16.4286C16.9809 6 17.4286 6.44772 17.4286 7C17.4286 7.55228 16.9809 8 16.4286 8H7C6.44772 8 6 7.55228 6 7Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const IconHelpCenter = ({
  title = "",
  fillSvg = "none",
  height = "18",
  viewBox = "0 0 18 18",
  width = "18",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM9.01 15C8.31 15 7.75 14.44 7.75 13.74C7.75 13.03 8.31 12.49 9.01 12.49C9.72 12.49 10.26 13.03 10.26 13.74C10.25 14.43 9.72 15 9.01 15ZM12.02 7.6C11.26 8.71 10.54 9.06 10.15 9.77C10.05 9.95 9.99 10.09 9.96 10.4C9.91 10.85 9.51 11.18 9.06 11.18H9C8.48 11.18 8.07 10.74 8.12 10.22C8.15 9.88 8.23 9.53 8.42 9.19C8.91 8.32 9.84 7.8 10.38 7.03C10.95 6.22 10.63 4.7 9.01 4.7C8.3 4.7 7.83 5.06 7.54 5.49C7.29 5.85 6.85 6.02 6.44 5.85C5.91 5.64 5.72 5 6.04 4.54C6.65 3.65 7.67 3 8.99 3C10.47 3 11.48 3.67 12 4.52C12.44 5.24 12.7 6.59 12.02 7.6Z"
        fill="white"
      />
    </svg>
  );
};

export const IconTemplatesV2 = ({
  title = "",
  fillSvg = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4444 3H8.55556C7.7 3 7 3.7 7 4.55556V15.4444C7 16.3 7.7 17 8.55556 17H19.4444C20.3 17 21 16.3 21 15.4444V4.55556C21 3.7 20.3 3 19.4444 3ZM14 14C14.5523 14 15 13.5523 15 13V11H17C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9H15V7C15 6.44772 14.5523 6 14 6C13.4477 6 13 6.44771 13 7V9H11C10.4477 9 10 9.44771 10 10C10 10.5523 10.4477 11 11 11H13V13C13 13.5523 13.4477 14 14 14Z"
        fill="#BBB9B8"
      />
      <path
        d="M5 6C5 5.44772 4.55228 5 4 5C3.44772 5 3 5.44772 3 6V18C3 19.6569 4.34315 21 6 21H18C18.5523 21 19 20.5523 19 20C19 19.4477 18.5523 19 18 19H6C5.44771 19 5 18.5523 5 18V6Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export const IconInitiativesNavigation = ({
  title = "",
  fillSvg = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"
        fill="#676565"
      />
    </svg>
  );
};

export const IconHelpCenterV2 = ({
  title = "",
  fillSvg = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M17.5 4.5C15.55 4.5 13.45 4.9 12 6C10.55 4.9 8.45 4.5 6.5 4.5C5.05 4.5 3.51 4.72 2.22 5.29C1.49 5.62 1 6.33 1 7.14V18.42C1 19.72 2.22 20.68 3.48 20.36C4.46 20.11 5.5 20 6.5 20C8.06 20 9.72 20.26 11.06 20.92C11.66 21.22 12.34 21.22 12.93 20.92C14.27 20.25 15.93 20 17.49 20C18.49 20 19.53 20.11 20.51 20.36C21.77 20.69 22.99 19.73 22.99 18.42V7.14C22.99 6.33 22.5 5.62 21.77 5.29C20.49 4.72 18.95 4.5 17.5 4.5ZM21 17.23C21 17.86 20.42 18.32 19.8 18.21C19.05 18.07 18.27 18.01 17.5 18.01C15.8 18.01 13.35 18.66 12 19.51V8C13.35 7.15 15.8 6.5 17.5 6.5C18.42 6.5 19.33 6.59 20.2 6.78C20.66 6.88 21 7.29 21 7.76V17.23Z"
        fill="#676565"
      />
      <path
        d="M13.98 11.01C13.66 11.01 13.37 10.81 13.27 10.49C13.14 10.1 13.36 9.67 13.75 9.55C15.29 9.05 17.28 8.89 19.11 9.1C19.52 9.15 19.82 9.52 19.77 9.93C19.72 10.34 19.35 10.64 18.94 10.59C17.32 10.4 15.55 10.55 14.21 10.98C14.13 10.99 14.05 11.01 13.98 11.01Z"
        fill="#676565"
      />
      <path
        d="M13.98 13.67C13.66 13.67 13.37 13.47 13.27 13.15C13.14 12.76 13.36 12.33 13.75 12.21C15.28 11.71 17.28 11.55 19.11 11.76C19.52 11.81 19.82 12.18 19.77 12.59C19.72 13 19.35 13.3 18.94 13.25C17.32 13.06 15.55 13.21 14.21 13.64C14.13 13.66 14.05 13.67 13.98 13.67Z"
        fill="#676565"
      />
      <path
        d="M13.98 16.33C13.66 16.33 13.37 16.13 13.27 15.81C13.14 15.42 13.36 14.99 13.75 14.87C15.28 14.37 17.28 14.21 19.11 14.42C19.52 14.47 19.82 14.84 19.77 15.25C19.72 15.66 19.35 15.95 18.94 15.91C17.32 15.72 15.55 15.87 14.21 16.3C14.13 16.32 14.05 16.33 13.98 16.33Z"
        fill="#676565"
      />
    </svg>
  );
};

export const IconMyTasks = ({
  title = "",
  fillSvg = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M3.5 6C3.5 5.44772 3.94772 5 4.5 5H8.5C9.05228 5 9.5 5.44772 9.5 6V10C9.5 10.5523 9.05228 11 8.5 11H4.5C3.94772 11 3.5 10.5523 3.5 10V6ZM5.5 7V9H7.5V7H5.5ZM12.5 8C12.5 7.44772 12.9477 7 13.5 7H21.0513C21.6036 7 22.0513 7.44772 22.0513 8C22.0513 8.55228 21.6036 9 21.0513 9H13.5C12.9477 9 12.5 8.55228 12.5 8ZM12.5 16C12.5 15.4477 12.9477 15 13.5 15H21.0513C21.6036 15 22.0513 15.4477 22.0513 16C22.0513 16.5523 21.6036 17 21.0513 17H13.5C12.9477 17 12.5 16.5523 12.5 16ZM6.20711 19.2929C5.81658 19.6834 5.18342 19.6834 4.79289 19.2929L2.70584 17.2058C2.31602 16.816 2.31602 16.184 2.70585 15.7942C3.09501 15.405 3.72574 15.4042 4.11584 15.7925L4.80384 16.4772C5.18871 16.8602 5.81088 16.8598 6.19525 16.4763L9.38462 13.2938C9.77431 12.905 10.4053 12.9053 10.7946 13.2946C11.1842 13.6842 11.1842 14.3158 10.7946 14.7054L6.20711 19.2929Z"
        fill="white"
      />
    </svg>
  );
};
