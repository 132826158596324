import { postAsyncRequest } from "gx-npm-lib";
import * as singleSpa from "single-spa";
import { reportFullStoryTemplateCreateEvent } from "./full-story-events.lib";

export const createInitiativeFromTemplateV2 = async (
  config: {
    templateId: number;
    templateName: string;
  },
  onError: (error: object) => void
) => {
  const { templateId } = config;
  const weightedPriorities = JSON.parse(sessionStorage.getItem(`weightedPriorities-${templateId}`) || "[]");
  const payload = { templateId: templateId, weightedPriorities };
  const url = "/api/v3/initiatives/";
  const response = await postAsyncRequest(url, payload);
  const initiativeId = response?.data?.data?.initiativeId || "";
  if (response.status === 201 && !!initiativeId) {
    localStorage.setItem("showRibbonAnimation", "true");
    sessionStorage.removeItem(`weightedPriorities-${templateId}`);
    reportFullStoryTemplateCreateEvent(config);
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/overview`);
  } else {
    onError(response);
  }
};
