import classNames from "classnames";
import { ProductLinkComponent, TypographyComponent } from "gx-npm-ui";
import React, { useContext, useState } from "react";
import { MarketProfileAppContext } from "../../../../app.context";
import styles from "./market-features-table-row.styles.module.scss";
import FeatureDescriptionPopper from "../feature-description-popper/feature-description-popper.component";

type MarketFeaturesTableRowProps = {
  name: string;
  description: string;
  topProducts: Array<{ id: number; imageLoc: string; name: string }>;
};

const MarketFeaturesTableRowComponent = ({
  name = "",
  description = "",
  topProducts = [],
}: MarketFeaturesTableRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);

  const handleOpenFeatureDescriptionPopper = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    setIsPopperOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFeatureDescriptionPopper = () => {
    setIsPopperOpen(false);
    setAnchorEl(null);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleOpenFeatureDescriptionPopper(event);
    }
  };

  const { templateId } = useContext(MarketProfileAppContext);
  return (
    <tr className={styles.tableBodyRow}>
      <td
        className={classNames(styles.tableBodyCell, styles.featuresCol)}
        onClick={(event) => handleOpenFeatureDescriptionPopper(event)}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <TypographyComponent rootClassName={styles.featureName} boldness="medium" styling="p3">
          {name}
        </TypographyComponent>
        {!isPopperOpen && (
          <>
            <div className={styles.shadeDiv} />
            <TypographyComponent color="coal" boldness="medium" styling="p4" rootClassName={styles.viewDetails}>
              View Details
            </TypographyComponent>
          </>
        )}
      </td>
      <td className={styles.tableBodyCell}>
        {Array.isArray(topProducts) &&
          topProducts.map((product, prodIdx) => {
            const url = `/s/market/${templateId}/product/${product.id}`;
            return (
              <ProductLinkComponent
                key={product.name}
                href={url}
                imageLoc={product.imageLoc}
                logoSize="24px"
                name={product.name}
                rootClassName={classNames(topProducts.length !== prodIdx + 1 && styles.multiProductLink)}
                textBoldness="regular"
                textType="p4"
              />
            );
          })}
      </td>
      <FeatureDescriptionPopper
        anchorEl={anchorEl}
        description={description}
        isOpen={isPopperOpen}
        name={name}
        onClose={handleCloseFeatureDescriptionPopper}
        topProducts={topProducts}
      />
    </tr>
  );
};

export default MarketFeaturesTableRowComponent;
