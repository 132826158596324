import classNames from "classnames";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { KeyboardEvent, MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { MarketProfileAppContext } from "../../../app.context";
import styles from "./breadcrumb-nav-v3.styles.module.scss";
import BreadcrumbNavChevronIcon from "./breadcrumb-nav-chevron.icon";

type BreadcrumbNavV3Props = { rootClassName?: string };
const BreadcrumbNavV3Component = ({ rootClassName = "" }: BreadcrumbNavV3Props) => {
  const { t } = useTranslation();
  const { name } = useContext(MarketProfileAppContext);
  const handleClick = (e: KeyboardEvent | MouseEvent) => {
    e.preventDefault();
    singleSpa.navigateToUrl("/s/markets");
  };

  return (
    <nav className={classNames(styles.breadcrumb, rootClassName)}>
      <div>
        <TextLink href="/s/markets" onClick={handleClick} text={t("Markets")} />
      </div>
      <BreadcrumbNavChevronIcon rootClassName={styles.chevronIcon} />
      <TypographyComponent boldness="medium" color="carbon" styling="p3">
        {name}
      </TypographyComponent>
    </nav>
  );
};

export default BreadcrumbNavV3Component;
