import classNames from "classnames";
import { postAsyncRequest, PriorityWeightOption } from "gx-npm-lib";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "../../typography/typography.component";
import { WeightedPriorityDrawerContext } from "../weighted-priority-drawer.context";
import PriorityRow from "./priority-row/priority-row.component";
import styles from "./priorities-form.styles.module.scss";

const ARTIFICIAL_LOADING_DELAY_MS = 500;
const FOOTER_HEIGHT = 96;
const REQUEST_DATA_DELAY_MS = 1500;
const PrioritiesFormComponent = () => {
  const { t } = useTranslation();
  const lastItemRef = useRef<HTMLLIElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [delayForApiCallTimer, setDelayForApiCallTimer] = useState<number>(0);
  const [isLoadIndicatorDelayComplete, setIsLoadIndicatorDelayComplete] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const [loadingCount, setLoadingCount] = useState(0);
  const {
    setHasUpdateError,
    setIsFooterInScrollingState,
    setIsLoading,
    setWeightedPriorities,
    setScoredProducts,
    updateRankUrl,
    weightedPriorities,
    scoredProducts,
  } = useContext(WeightedPriorityDrawerContext);

  useEffect(() => {
    setIsLoading(loadingCount > 0 || !isLoadIndicatorDelayComplete);
  }, [isLoadIndicatorDelayComplete, loadingCount, setIsLoading]);

  useEffect(() => {
    const listElement = listRef.current;
    const lastItem = lastItemRef.current;
    if (!lastItem || !listElement || weightedPriorities.length === 0) {
      return;
    }

    const handleScroll = () => {
      setIsAtTop(listElement.scrollTop === 0);
      const lastItemPositionOnScroll = lastItem.getBoundingClientRect();
      setIsFooterInScrollingState(lastItemPositionOnScroll.bottom >= window.innerHeight - FOOTER_HEIGHT);
    };

    handleScroll();
    listElement.addEventListener("scroll", handleScroll);
    return () => {
      listElement.removeEventListener("scroll", handleScroll);
    };
  }, [setIsFooterInScrollingState, weightedPriorities]);

  const handleChangePriorityWeight = (id: string, weight: PriorityWeightOption) => {
    setIsLoadIndicatorDelayComplete(false);
    const updatedPriorities = weightedPriorities.map((wp) => (wp.id === id ? { ...wp, weight } : wp));
    setWeightedPriorities(updatedPriorities);

    if (updatedPriorities.every((wp) => wp.weight === PriorityWeightOption.NONE)) {
      setIsLoadIndicatorDelayComplete(true);
      window.clearTimeout(delayForApiCallTimer);
      setScoredProducts(scoredProducts.map((p) => ({ ...p, priorityScore: 0 })));
      return;
    }
    const hasNoScores = scoredProducts.every((p) => p.priorityScore <= 0);
    const waitDelay = hasNoScores || loadingCount !== 0 ? 0 : REQUEST_DATA_DELAY_MS;
    const timer = window.setTimeout(() => {
      (async () => {
        setLoadingCount((prev) => prev + 1);
        window.setTimeout(() => setIsLoadIndicatorDelayComplete(true), ARTIFICIAL_LOADING_DELAY_MS);
        const priorityWeights = updatedPriorities.map((wp) => ({ id: wp.id, weight: wp.weight }));
        const payload = { weightedPriorities: priorityWeights };
        const response = await postAsyncRequest(updateRankUrl, payload);
        if (response.status === 201 && Array.isArray(response.data?.data?.products)) {
          setScoredProducts(response.data.data.products);
        } else {
          setHasUpdateError(true);
        }
        setLoadingCount((prev) => prev - 1);
      })();
    }, waitDelay);
    window.clearTimeout(delayForApiCallTimer);
    setDelayForApiCallTimer(timer);
  };

  return (
    <section className={styles.formContainer}>
      <div className={classNames(!isAtTop && styles.isScrolling, styles.textWrapper)}>
        <TypographyComponent boldness="medium" color="coal" styling={"p4"}>
          {t("Features and functionality")}
          {` (${weightedPriorities.length})`}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.priorityText} boldness="medium" color="coal" styling={"p4"}>
          {t("Priority")}
        </TypographyComponent>
      </div>
      <ul ref={listRef} className={styles.list}>
        {weightedPriorities.map((item, index) => {
          const isLast = index === weightedPriorities.length - 1;
          return (
            <li
              key={item.id}
              ref={isLast ? lastItemRef : null}
              className={classNames(
                styles.priorityItem,
                index === 0 && styles.firstPriority,
                isLast && styles.lastPriority
              )}
            >
              <PriorityRow
                name={item.name}
                onChangePriority={(weight) => handleChangePriorityWeight(item.id, weight)}
                weight={item.weight}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default PrioritiesFormComponent;
