import { useContext } from "react";
import { MarketProfileAppContext } from "../../../../../app.context";
import ProductFitBarComponent from "./product-fit-bar/product-fit-bar.component";
import styles from "./vendor-fit-cell.styles.module.scss";

type VendorFitCellProps = { priorityScore: number; name: string };
const VendorFitCell = ({ priorityScore = 0, name = "" }: VendorFitCellProps) => {
  const { products } = useContext(MarketProfileAppContext);
  return (
    <div className={styles.root}>
      <ProductFitBarComponent fitScore={priorityScore} productName={name} totalProducts={products.length} />
    </div>
  );
};

export default VendorFitCell;
