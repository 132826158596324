import { Fade } from "@material-ui/core";
import classNames from "classnames";
import { PriorityWeightOption } from "gx-npm-lib";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LinearProgressBar from "../../linear-progress-bar/linear-progress-bar.component";
import Loader from "../../loader/loader";
import { ProductLogo } from "../../productLogo";
import { TypographyComponent } from "../../typography/typography.component";
import NoWeightedPrioritiesSVG from "../assets/no-weight-priorities.svg";
import { WeightedPriorityDrawerContext } from "../weighted-priority-drawer.context";
import { ScoredProduct } from "../weighted-priority-drawer.types";
import styles from "./product-fit-results.styles.module.scss";

const LIST_FADE_IN_SPEED_MS = 750;
const ProductFitResultsComponent = () => {
  const { t } = useTranslation();
  const { isFadeInTriggered, isLoading, scoredProducts, weightedPriorities, setIsFooterInScrollingState } =
    useContext(WeightedPriorityDrawerContext);
  const selectedOptions = [PriorityWeightOption.LOW, PriorityWeightOption.MEDIUM, PriorityWeightOption.HIGH];
  const hasSelectedPriorities = weightedPriorities.some((item) => selectedOptions.includes(item.weight));
  const hasProductScores = hasSelectedPriorities && scoredProducts.some((p) => p.priorityScore > 0);
  const lastItemRef = useRef<HTMLLIElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const FOOTER_HEIGHT = 96;

  useEffect(() => {
    const listElement = listRef.current;
    const lastItem = lastItemRef.current;
    if (!lastItem || !listElement || !hasProductScores || isLoading) {
      return;
    }
    const handleScroll = () => {
      setIsAtTop(listElement.scrollTop === 0);
      const lastItemPositionOnScroll = lastItem.getBoundingClientRect();
      setIsFooterInScrollingState(lastItemPositionOnScroll.bottom >= window.innerHeight - FOOTER_HEIGHT);
    };

    handleScroll();
    listElement.addEventListener("scroll", handleScroll);
    return () => {
      listElement.removeEventListener("scroll", handleScroll);
    };
  }, [setIsFooterInScrollingState, isLoading, hasProductScores]);

  return (
    <section className={styles.resultsContainer}>
      {!hasProductScores && (
        <div className={styles.noPrioritiesSection}>
          <TypographyComponent boldness="medium" color="iron" rootClassName={styles.noPrioritiesText} styling={"h5"}>
            {t("Add priorities from the list on the left to see how well each vendor fits your needs.")}
          </TypographyComponent>

          <img alt="" className={styles.noPrioritiesImg} src={NoWeightedPrioritiesSVG} />
        </div>
      )}
      {hasProductScores && isLoading && (
        <div className={styles.loaderContainer}>
          <Loader rootClassName={styles.loader} size={48} />
        </div>
      )}
      {hasProductScores && !isLoading && (
        <div>
          <div className={classNames(styles.productsHeader, !isAtTop && styles.isScrolling)}>
            <TypographyComponent boldness="medium" color="coal" styling={"p4"}>
              {t("Vendor")}
            </TypographyComponent>
            <TypographyComponent boldness="medium" color="coal" rootClassName={styles.lastColumnTitle} styling={"p4"}>
              {t("Vendor fit")}
            </TypographyComponent>
          </div>
          <Fade timeout={{ enter: LIST_FADE_IN_SPEED_MS }} in={isFadeInTriggered}>
            <ul ref={listRef} className={styles.productsBody}>
              {scoredProducts.map((product: ScoredProduct, idx: number) => {
                const isLast = idx === scoredProducts.length - 1;
                return (
                  <li
                    ref={isLast ? lastItemRef : null}
                    key={product.id}
                    className={classNames(
                      styles.scoredProductItem,
                      idx === scoredProducts.length - 1 && styles.lastItem
                    )}
                  >
                    <div className={styles.productNameColumn}>
                      <ProductLogo imageHeight="32px" imageWidth="32px" logo={product.imageLoc} />
                      <TypographyComponent
                        boldness="medium"
                        color="carbon"
                        rootClassName={styles.productName}
                        styling={"p2"}
                      >
                        {product.name}
                      </TypographyComponent>
                    </div>
                    <div className={styles.productFitColumn}>
                      {product.priorityScore > 0 && (
                        <LinearProgressBar
                          label={`${product.name} has a score of ${product.priorityScore} out of ${scoredProducts.length} vendors`}
                          score={product.priorityScore}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </Fade>
        </div>
      )}
    </section>
  );
};

export default ProductFitResultsComponent;
