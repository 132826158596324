import Popper from "@mui/material/Popper";
import styles from "./rich-tooltip.styles.module.scss";
import { ClickAwayListener } from "@mui/material";
import { IconButton } from "../iconButton";
import { ArrowRoundedTip, ArrowRoundedTipWithBorder, CloseIcon } from "gx-npm-icons";
import classNames from "classnames";
import { TypographyComponent } from "../typography/typography.component";
import { RichTooltipProps } from "./rich-tooltip.types";
import { TextLink } from "../textLink";
import { colorPalette } from "gx-npm-common-styles";
import { useState } from "react";

const RichTooltip = ({
  theme = "dark",
  title = "Gartner Insight",
  onClose = () => {},
  content = [],
  open = false,
  anchor = null,
  footerLinkText = "",
  footerLinkUrl = "",
  popperPlacement = "bottom",
  children,
  rootClassName = "",
}: RichTooltipProps) => {
  const [currentPlacement, setCurrentPlacement] = useState<RichTooltipProps["popperPlacement"]>(popperPlacement);
  return (
    <ClickAwayListener onClickAway={onClose}>
      <span className={rootClassName}>
        {children}
        <Popper
          open={open}
          anchorEl={anchor}
          placement={popperPlacement}
          className={styles.root}
          onClick={(e) => e.stopPropagation()}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 12],
              },
            },
            {
              name: "eventListeners",
              enabled: true,
              options: {
                scroll: true,
                resize: true,
              },
              fn({ state }) {
                if (currentPlacement !== state.placement) {
                  setCurrentPlacement(state.placement as RichTooltipProps["popperPlacement"]);
                }
              },
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["top", "bottom", "left", "right"],
              },
            },
          ]}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.shadow]: popperPlacement !== "top",
              [styles.darkTheme]: theme === "dark",
              [styles.lightTheme]: theme === "light",
            })}
          >
            <section className={styles.header}>
              <TypographyComponent styling="p2" boldness="medium" rootClassName={styles.title}>
                {title}
              </TypographyComponent>
              <IconButton
                ariaLabel={"close"}
                onClick={onClose}
                className={styles.closeBtnWrapper}
                hoverFill={theme === "dark" ? colorPalette.basic.white.hex : colorPalette.neutrals.iron.hex}
                hoverBackgroundColor={
                  theme === "dark" ? colorPalette.brand.gartnerDarkBlue.hex : colorPalette.basic.white.hex
                }
              >
                <CloseIcon />
              </IconButton>
            </section>
            <section className={styles.content}>
              {content.map((item, index) => {
                if (item.type === "PARAGRAPH") {
                  return (
                    <TypographyComponent styling="p4" key={index.toString()}>
                      {item.content}
                    </TypographyComponent>
                  );
                } else if (item.type === "LIST_ITEMS") {
                  return (
                    <ul className={styles.list} key={index}>
                      {typeof item.content !== "string"
                        ? item.content?.map((listItem: string, i: number) => {
                            return (
                              <li className={styles.listItem} key={i.toString() + listItem.replace(/\s+/, "")}>
                                <TypographyComponent styling="p4">{listItem}</TypographyComponent>
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  );
                }
              })}
            </section>
            <section className={styles.footer}>
              <TextLink
                rootClassName={styles.footerLink}
                text={footerLinkText}
                href={footerLinkUrl}
                variant="secondary"
                target="_blank"
              />
            </section>
          </div>
          <span
            className={classNames(styles.arrow, {
              [styles.arrowUpward]: currentPlacement === "bottom",
              [styles.arrowDownward]: currentPlacement === "top",
              [styles.arrowLeft]: currentPlacement === "right",
              [styles.arrowRight]: currentPlacement === "left",
              [styles.darkTheme]: theme === "dark",
              [styles.lightTheme]: theme === "light",
            })}
          >
            {theme === "dark" ? <ArrowRoundedTip /> : <ArrowRoundedTipWithBorder />}
          </span>
        </Popper>
      </span>
    </ClickAwayListener>
  );
};

export default RichTooltip;
