// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ryjrk3sfDHX0M0HDKLd1.mini-btn{margin-top:16px}.ryjrk3sfDHX0M0HDKLd1.mini-btn.dmAjovFpi7lyZPmX51Ak .gx-expand-collapse-arrow{transform:rotate(180deg)}`, "",{"version":3,"sources":["webpack://./src/sections/market-overview-section/market-features-table/market-features-entitled-table/expand-collapse-features-button/expand-collapse-features-button.styles.module.scss"],"names":[],"mappings":"AAAA,+BACE,eAAA,CAEA,8EACE,wBAAA","sourcesContent":[".expandCollapseBtn:global(.mini-btn) {\n  margin-top: 16px;\n\n  &.isExpanded :global(.gx-expand-collapse-arrow) {\n    transform: rotate(180deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandCollapseBtn": `ryjrk3sfDHX0M0HDKLd1`,
	"isExpanded": `dmAjovFpi7lyZPmX51Ak`
};
export default ___CSS_LOADER_EXPORT___;
