// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g7GI9nwZJoH_xuNzrRlN._4eXtJQ3kK4MtjKoK5hRn{background:var(--color-neutrals-quartz);border-radius:8px;padding:32px 32px 0 32px}`, "",{"version":3,"sources":["webpack://./src/sections/market-overview-section/market-features-table/market-features-table.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,uCAAA,CACA,iBAAA,CACA,wBAAA","sourcesContent":[".tableContentWrapper {\n  &.isNotEntitled {\n    background: var(--color-neutrals-quartz);\n    border-radius: 8px;\n    padding: 32px 32px 0 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContentWrapper": `g7GI9nwZJoH_xuNzrRlN`,
	"isNotEntitled": `_4eXtJQ3kK4MtjKoK5hRn`
};
export default ___CSS_LOADER_EXPORT___;
