import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Button } from "../button";
import { ButtonLoader } from "../button-loader/button-loader.component";
import { Dialog } from "../dialog";
import { dialogWithTextboxStyles as styles } from "./styles";
import { TextField } from "../textField";
import { TypographyComponent } from "../typography/typography.component";

const propTypes = {
  autoFocus: PropTypes.bool,
  confirmPasscode: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isShowingConfirm: PropTypes.bool,
  labelCancelBtn: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelConfirmBtn: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelDescription: PropTypes.any,
  labelTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClickClose: PropTypes.func,
  onClickConfirm: PropTypes.func,
};

const useStyles = makeStyles(() => styles);
const DialogWithTextbox = ({
  autoFocus = false,
  confirmPasscode = "",
  isLoading = false,
  isOpen = false,
  isShowingConfirm = true,
  labelCancelBtn = "",
  labelConfirmBtn = "",
  labelDescription,
  labelTitle = "",
  onClickClose = () => {},
  onClickConfirm = () => {},
}) => {
  const classes = useStyles();
  const [confirmText, setConfirmText] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(isShowingConfirm);

  const handleClose = () => {
    setConfirmText("");
    onClickClose();
  };

  const handleConfirm = () => {
    setConfirmDisabled(true);
    onClickConfirm();
  };

  useEffect(() => {
    const value =
      isShowingConfirm &&
      !(confirmText && confirmPasscode && confirmText.toLowerCase() === confirmPasscode.toLowerCase());
    setConfirmDisabled(value);
  }, [confirmPasscode, confirmText, isShowingConfirm]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      title={
        <TypographyComponent styling={"h4"} boldness={"medium"}>
          {labelTitle}
        </TypographyComponent>
      }
      body={
        <Fragment>
          <div className={classes.body}>{labelDescription}</div>
          {isShowingConfirm && (
            <div>
              <TextField
                autoFocus={autoFocus}
                fullWidth
                label={`Type “${confirmPasscode}“ to confirm`}
                onChange={(e) => setConfirmText(e.target.value)}
                value={confirmText}
              />
            </div>
          )}
        </Fragment>
      }
      footer={
        <div className={classes.footer}>
          <Button rootClassName="btn-tertiary" onClick={handleClose}>
            {labelCancelBtn}
          </Button>
          <div className={classes.confirmBtnWrapper}>
            <ButtonLoader
              disabled={confirmDisabled}
              btnClass={confirmDisabled ? "btn-primary" : "primary-destructive-btn"}
              isLoading={isLoading}
              onClick={handleConfirm}
            >
              {labelConfirmBtn}
            </ButtonLoader>
          </div>
        </div>
      }
    />
  );
};

DialogWithTextbox.propTypes = propTypes;
export default DialogWithTextbox;
