import React from "react";
import { SlideInOverlay, TextLink, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import classes from "./research-slide-in.styles.module.scss";
import { ReactElementLike } from "prop-types";
import classNames from "classnames";
import { StarV2Icon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import MagicQBody from "./components/magic-q-body.component";
import MarketGuideBody from "./components/market-guide-body.component";
import ResearchSlideInHeaderLogoComponent from "./components/research-slide-in-header-logo.component";

const ResearchSlideIn = ({
  isDrawerOpen = false,
  handleOnClick = () => {},
  isMagicQ = false,
  mqResId = -1,
  mgResId = -1,
  piMarketSeoName = "",
  templateName = "",
}) => {
  const { t } = useTranslation();

  return (
    <SlideInOverlay
      isOpen={isDrawerOpen}
      onClose={handleOnClick}
      darth={true}
      textBackBtn={t("eval")}
      headerLogo={(<ResearchSlideInHeaderLogoComponent />) as ReactElementLike}
    >
      <section>
        <TypographyComponent rootClassName={classNames(classes.contentHeader)} styling={"h2"} color={"quartz"}>
          {templateName}
        </TypographyComponent>
        <div className={classNames(classes.contentHeaderLine)} />
        <div>
          <div>
            <TypographyComponent
              rootClassName={classNames(classes.contentBodyGartnerResearchTitle)}
              styling={"h3"}
              color={"berry"}
            >
              {t("Gartner research behind this market")}
            </TypographyComponent>
            <TypographyComponent
              element={"p"}
              rootClassName={classNames(classes.contentBodyGartnerResearchDesc)}
              color={"quartz"}
            >
              {t(
                "BuySmart combines the power of curated Gartner research, peer and expert insights, and intuitive workflow processes into markets. Each market is based on Gartner research that uses our proprietary methodologies and an independent operating model for objective business and technology insights."
              )}
            </TypographyComponent>
            <TypographyComponent
              element={"p"}
              rootClassName={classNames(classes.contentBodyGartnerResearchDesc)}
              color={"quartz"}
            >
              {t(
                "This market has been created from a variety of published Gartner research documents and data sources."
              )}
            </TypographyComponent>
          </div>
          <div className={classNames(classes.contentBodyMidSection)}>
            {isMagicQ ? (
              <MagicQBody documentQuery={mqResId} templateName={templateName} />
            ) : (
              <MarketGuideBody documentQuery={mgResId} templateName={templateName} />
            )}
            <div className={classNames(classes.contentBodyMidSectionPeerInsights)}>
              <TypographyComponent
                styling={"h5"}
                rootClassName={classNames(classes.contentBodyMidSectionTitleText)}
                color={"berry"}
              >
                <StarV2Icon height={"18px"} width={"19px"} fillPath={colorPalette.interactions.berry.hex} />
                {t("Peer Insights")}
              </TypographyComponent>
              <TypographyComponent
                styling={"p2"}
                rootClassName={classNames(classes.contentBodyMidSectionDescText, classes.gxGartnerMqMgPiDescTextRight)}
                color={"quartz"}
              >
                {t(
                  "Peer Insights is Gartners peer-driven platform where thousands of enterprise leaders connect with each other to help guide technology and business decisions. You can access peer technology rating and reviews by verified users, network, share knowledge, get advice, and stay on top of current trends in this technology space."
                )}
              </TypographyComponent>
              <div className={classNames(classes.contentLearnMoreLine)} />
              <TypographyComponent
                element={"p"}
                rootClassName={classNames(classes.contentBodyMidSectionLearnMore)}
                color={"stone"}
              >
                {t("Learn more")}
              </TypographyComponent>
              <div>
                <TextLink
                  rootClassName={classNames(classes.contentBodyMidSectionLinkText)}
                  href={"https://www.gartner.com/reviews/market/" + piMarketSeoName}
                  target="_blank"
                  text={t(`Peer Insights for ${templateName}`)}
                  variant={"dark"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </SlideInOverlay>
  );
};

export default ResearchSlideIn;
