import * as singleSpa from "single-spa";

const defaultRoute = "/s/evaluations";

/**
 * @description :: this code will handle the following paths
 * 1. s/market-profile/:marketId
 * 2. s/market-profile/market-preview/:marketId
 */
const HandleRedirects = () => {
  const { pathname, search } = window.location;
  const parts = pathname.split("/");
  const templateId = parseInt(parts[parts.length - 1]);

  singleSpa.navigateToUrl(
    templateId ? `/s/market/${templateId}${pathname.includes("preview") ? "/preview" : ""}${search}` : defaultRoute
  );
  return null;
};

export default HandleRedirects;
