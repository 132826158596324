import classNames from "classnames";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../button";
import { ButtonLoader } from "../../button-loader/button-loader.component";
import { Dialog } from "../../dialog";
import { TypographyComponent } from "../../typography/typography.component";
import InitExampleCornerImageSVG from "./init-example-corner-image.svg";
import styles from "./free-trial-init-create-modal.styles.module.scss";

const MAX_INITS = 3;
export type FreeTrialInfoModalProps = {
  currentInits?: number;
  isOpen?: boolean;
  isProcessing?: boolean;
  onClickClose?: () => void;
  onClickConfirm?: () => void;
};
const FreeTrialInitCreateModalComponent = ({
  currentInits = 0,
  isOpen = false,
  isProcessing = false,
  onClickClose = () => {},
  onClickConfirm = () => {},
}: FreeTrialInfoModalProps) => {
  const { t } = useTranslation();
  const initsRemaining = MAX_INITS - currentInits;

  return (
    <Dialog
      body={
        <Fragment>
          <div className={styles.subtitleParagraph}>
            <TypographyComponent element="p">
              <TypographyComponent color="carbon" element="span" styling="p2">
                {t(
                  "Your Gartner BuySmart free trial includes up to three evaluations that can be accessed for 30 days after the trial begins."
                )}
              </TypographyComponent>
              {` `}
              <TypographyComponent color="carbon" element="span" styling="p2">
                {t("Would you like to start one of your evaluations?")}
              </TypographyComponent>
            </TypographyComponent>
          </div>
          <div className={styles.remainingInitsHeading}>
            <TypographyComponent element="h5">
              <TypographyComponent color="carbon" element="span" styling="h1">
                {initsRemaining}
              </TypographyComponent>
              {` `}
              <TypographyComponent color="iron" element="span" styling="h4">
                {t("of")}
              </TypographyComponent>
              {` `}
              <TypographyComponent color="carbon" element="span" styling="h1">
                {MAX_INITS}
              </TypographyComponent>
              {` `}
              <TypographyComponent color="carbon" element="span" rootClassName={styles.headingSecondLine} styling="h5">
                {t("Evaluations remaining")}
              </TypographyComponent>
            </TypographyComponent>
            <div className={styles.cornerImgContainer}>
              <img alt="" className={styles.cornerImg} src={InitExampleCornerImageSVG} />
            </div>
          </div>
        </Fragment>
      }
      closeIcon={true}
      footer={
        <div className={styles.footerButtonContainer}>
          <Button onClick={onClickClose} rootClassName={classNames(styles.cancelButton, "btn-tertiary")}>
            {t("CANCEL")}
          </Button>
          <ButtonLoader btnClass="btn-primary" isLoading={isProcessing} onClick={onClickConfirm}>
            {t("START EVALUATION")}
          </ButtonLoader>
        </div>
      }
      onClose={onClickClose}
      open={isOpen}
      rootClassName={styles.dialogRoot}
      title={t("Start new evaluation")}
    />
  );
};

export { FreeTrialInitCreateModalComponent };
