import { colorPalette } from "gx-npm-common-styles";

type BreadcrumbNavChevronProps = { rootClassName: string };
const BreadcrumbNavChevronIcon = ({ rootClassName }: BreadcrumbNavChevronProps) => {
  return (
    <svg
      className={rootClassName}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.28957 15.8814L13.1696 12.0014L9.28957 8.12145C8.89957 7.73145 8.89957 7.10145 9.28957 6.71145C9.67957 6.32145 10.3096 6.32145 10.6996 6.71145L15.2896 11.3014C15.6796 11.6914 15.6796 12.3214 15.2896 12.7114L10.6996 17.3014C10.3096 17.6914 9.67957 17.6914 9.28957 17.3014C8.90957 16.9114 8.89957 16.2714 9.28957 15.8814Z"
        fill={colorPalette.neutrals.pewter.hex}
      />
    </svg>
  );
};

export default BreadcrumbNavChevronIcon;
