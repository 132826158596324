import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormControlLabel, makeStyles, Radio as MuiRadioButton } from "@material-ui/core";
import styles from "./radio-button.styles";

const variantRadioButtonType = {
  darkTheme: "darkTheme",
  default: "",
};
const propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  rootClassName: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(variantRadioButtonType)),
};
const useStyles = makeStyles(() => styles);
/**
 * @param {boolean} checked - flag for if checkbox is checked
 * @param {boolean} disabled - flag for if checkbox is clickable
 * @param {object} inputProps - props to pass to the input element of checkbox
 * @param {string} label - string or object to pass to mui form control label if used
 * @param {string} name - name of input
 * @param {Function} onChange - function to invoke when checked or unchecked
 * @param {string} rootClassName - css class to determine style
 * @param {string} variant - applies styles based on variant type, options are darkTheme
 * @param {string} value - value of input
 * @param {object} others - any other props to pass to mui radio button
 * @returns mui radio button wrapper
 */
const RadioButton = ({
  checked,
  disabled = false,
  inputProps = {},
  label = "",
  name = "",
  onChange = (_event) => {},
  rootClassName = "",
  value = "",
  variant = "",
  ...others
}) => {
  const classes = useStyles();

  const renderRadioButton = () => {
    return (
      <MuiRadioButton
        {...others}
        aria-label="radio"
        checked={checked}
        className={classnames(
          "gx-radio",
          classes.radio,
          variant === variantRadioButtonType.darkTheme && "dark-theme-radio"
        )}
        disabled={disabled}
        inputProps={inputProps}
        name={name}
        onChange={onChange}
        value={value}
      />
    );
  };

  return (
    <div className={classnames("gx-radio-container", rootClassName && rootClassName)}>
      {label ? (
        <FormControlLabel
          classes={{
            label: classnames(
              "gx-radio-label",
              classes.label,
              variant === variantRadioButtonType.darkTheme && "dark-theme-radio"
            ),
          }}
          className={classes.label}
          control={renderRadioButton()}
          label={label}
        />
      ) : (
        renderRadioButton()
      )}
    </div>
  );
};

RadioButton.propTypes = propTypes;
export { RadioButton, variantRadioButtonType };
