// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zKqMCoM7432GPLhBGrhq{margin-left:auto;margin-right:24px}`, "",{"version":3,"sources":["webpack://./src/sections/market-products/product-row/cells/vendor-fit-cell/vendor-fit-cell.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,iBAAA","sourcesContent":[".root {\n  margin-left: auto;\n  margin-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `zKqMCoM7432GPLhBGrhq`
};
export default ___CSS_LOADER_EXPORT___;
