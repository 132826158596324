import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { RibbonBulbIcon } from "gx-npm-icons";
import classes from "./research-slide-in-header-logo.styles.module.scss";
import { TypographyComponent } from "../../../typography/typography.component";

const ResearchSlideInHeaderLogoComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.researchSlideInHeaderLogoStyles)}>
      <RibbonBulbIcon data-testid="ribbon-bulb-expanded" />
      <TypographyComponent styling={"p4"} color={"inherit"}>
        {t("Gartner research behind this market")}
      </TypographyComponent>
    </div>
  );
};
export default ResearchSlideInHeaderLogoComponent;
