import { useTranslation } from "react-i18next";
import { Button } from "../../button";
import { Dialog } from "../../dialog";
import { TypographyComponent } from "../../typography/typography.component";
import { CancelDialogAction } from "./confirm-cancel-dialog.constants";
import styles from "./confirm-cancel-dialog.styles.module.scss";
import classNames from "classnames";

const ConfirmCancelDialogComponent = ({ isOpen = false, onClose = (_closeAction: CancelDialogAction) => {} }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      body={
        <TypographyComponent color="coal" type="p2">
          {t("Exiting vendor fit settings will discard any changes you may have made. Are you sure you want to exit?")}
        </TypographyComponent>
      }
      footer={
        <div className={styles.buttonWrapper}>
          <Button
            onClick={() => onClose(CancelDialogAction.STAY_OPEN)}
            rootClassName={classNames("btn-tertiary", styles.cancelButton)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            onClick={() => onClose(CancelDialogAction.CONFIRMED_TO_CLOSE)}
            rootClassName="primary-destructive-btn"
          >
            {t("EXIT AND DISCARD CHANGES")}
          </Button>
        </div>
      }
      open={isOpen}
      title={t("Exit priorities settings")}
    />
  );
};

export default ConfirmCancelDialogComponent;
