// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hOkI85xtMhW03ExYMB3Q{display:inline-flex}.hOkI85xtMhW03ExYMB3Q .MdMOzQK3jl8d0wllMs3E{color:var(--color-neutrals-coal);margin-bottom:28px;margin-left:18px;max-width:500px}.FAm9HpNw__DgYyHrGtGe{border-collapse:collapse;width:100%}.FAm9HpNw__DgYyHrGtGe .mv6fJrXjQfYwQHhSEpz7 .fBIdo1Cgr1jT0cdIBrQo{border-bottom:1px solid var(--color-neutrals-stone);height:48px}.FAm9HpNw__DgYyHrGtGe .mv6fJrXjQfYwQHhSEpz7 .fBIdo1Cgr1jT0cdIBrQo:last-child{border-bottom:none;opacity:.6}.FAm9HpNw__DgYyHrGtGe .mv6fJrXjQfYwQHhSEpz7 .fBIdo1Cgr1jT0cdIBrQo .ne7MkxMEs9wZ3AgClG31,.FAm9HpNw__DgYyHrGtGe .mv6fJrXjQfYwQHhSEpz7 .fBIdo1Cgr1jT0cdIBrQo .fAe0RVrHyBCdNRM7eTwg{margin-right:16px}`, "",{"version":3,"sources":["webpack://./src/sections/market-overview-section/market-features-table/market-features-unentitled-table/market-features-unentitled-table.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAEA,4CACE,gCAAA,CACA,kBAAA,CACA,gBAAA,CACA,eAAA,CAIJ,sBACE,wBAAA,CACA,UAAA,CAEA,kEACE,mDAAA,CACA,WAAA,CACA,6EACE,kBAAA,CACA,UAAA,CAGF,gLACE,iBAAA","sourcesContent":[".unentitledTitleContainer {\n  display: inline-flex;\n\n  & .unentitledTitleText {\n    color: var(--color-neutrals-coal);\n    margin-bottom: 28px;\n    margin-left: 18px;\n    max-width: 500px;\n  }\n}\n\n.unentitledTableContainer {\n  border-collapse: collapse;\n  width: 100%;\n\n  .unentitledTableBody .unentitledTableRow {\n    border-bottom: 1px solid var(--color-neutrals-stone);\n    height: 48px;\n    &:last-child {\n      border-bottom: none;\n      opacity: 0.6;\n    }\n\n    & .unentitledProductIcon, & .unentitledSpacedBar {\n      margin-right: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unentitledTitleContainer": `hOkI85xtMhW03ExYMB3Q`,
	"unentitledTitleText": `MdMOzQK3jl8d0wllMs3E`,
	"unentitledTableContainer": `FAm9HpNw__DgYyHrGtGe`,
	"unentitledTableBody": `mv6fJrXjQfYwQHhSEpz7`,
	"unentitledTableRow": `fBIdo1Cgr1jT0cdIBrQo`,
	"unentitledProductIcon": `ne7MkxMEs9wZ3AgClG31`,
	"unentitledSpacedBar": `fAe0RVrHyBCdNRM7eTwg`
};
export default ___CSS_LOADER_EXPORT___;
