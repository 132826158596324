// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cErakUPEI0vk1B1_Uakg{align-items:center;display:flex;line-height:72px}.cErakUPEI0vk1B1_Uakg .OctKjE2J01Itroy9gjU1{margin:0 4px}`, "",{"version":3,"sources":["webpack://./src/sections/header/breadcrumb-nav-v3/breadcrumb-nav-v3.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,gBAAA,CAEA,4CACE,YAAA","sourcesContent":[".breadcrumb {\n  align-items: center;\n  display: flex;\n  line-height: 72px;\n\n  & .chevronIcon {\n    margin: 0 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `cErakUPEI0vk1B1_Uakg`,
	"chevronIcon": `OctKjE2J01Itroy9gjU1`
};
export default ___CSS_LOADER_EXPORT___;
