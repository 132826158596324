// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bjcy399fv3UWU00lxGhg{border-collapse:collapse;width:100%}.bjcy399fv3UWU00lxGhg .yOyv_mnApcCjuoENBNJK .rT45JrpjTgU8c3bxcRJ0{border-bottom:1px solid var(--color-neutrals-stone);padding-bottom:8px;text-align:left;width:50%}.bjcy399fv3UWU00lxGhg .qbABrdO5UPMw703N5teC{visibility:hidden}.bjcy399fv3UWU00lxGhg .qbABrdO5UPMw703N5teC .rT45JrpjTgU8c3bxcRJ0{border-bottom:none;padding-bottom:0}`, "",{"version":3,"sources":["webpack://./src/sections/market-overview-section/market-features-table/market-features-entitled-table/market-features-entitled-table.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,UAAA,CAEC,kEACC,mDAAA,CACA,kBAAA,CACA,eAAA,CACA,SAAA,CAGF,4CACE,iBAAA,CACA,kEACE,kBAAA,CACA,gBAAA","sourcesContent":[".tableContainer {\n  border-collapse: collapse;\n  width: 100%;\n\n   & .tableHeader .tableHeaderCell {\n    border-bottom: 1px solid var(--color-neutrals-stone);\n    padding-bottom: 8px;\n    text-align: left;\n    width: 50%;\n  }\n\n  & .tableCollapsibleHeader {\n    visibility: hidden;\n    & .tableHeaderCell {\n      border-bottom: none;\n      padding-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `bjcy399fv3UWU00lxGhg`,
	"tableHeader": `yOyv_mnApcCjuoENBNJK`,
	"tableHeaderCell": `rT45JrpjTgU8c3bxcRJ0`,
	"tableCollapsibleHeader": `qbABrdO5UPMw703N5teC`
};
export default ___CSS_LOADER_EXPORT___;
