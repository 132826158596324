import { postAsyncRequest, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { GartnerFooter, Loader, SnackbarBanner } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "./sections/footer/footer.component";
import HeaderV3Component from "./sections/header/header-v3.component";
import MarketOverviewSectionComponent from "./sections/market-overview-section/market-overview-section.component";
import MarketProducts from "./sections/market-products/market-products.component";
import MoreFromGartner from "./sections/more-from-gartner/more-from-gartner.component";
import { ClientEvent } from "./app.constants";
import { MarketProfileAppContext } from "./app.context";
import styles from "./app.styles.module.scss";
import { MarketProfileOverviewResponse } from "./app.types";

const AppV2 = () => {
  const { marketId } = useParams();
  const [hasError, setHasError] = useState(false);
  const {
    isLoading,
    name,
    setChecklistCount,
    setDescription,
    setFeaturesAndFunctionalities,
    setGcomCcDocCd,
    setGcomCcResId,
    setGcomMgResId,
    setGcomMqResId,
    setHasSelectedPriorities,
    setIsLoading,
    setLatestExistingInitId,
    setMoreFromGartner,
    setName,
    setPiMarketSeoName,
    setProductCount,
    setProducts,
    setRequirementCount,
    setTemplateId,
    setUserInitCount,
  } = useContext(MarketProfileAppContext);
  const { hashedUserId, isEntitled } = useUserState();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!marketId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.MARKET_PROFILE_PAGE_VIEWED, metaData: { templateId: marketId } }]);
  }, [captureEvents, marketId]);

  useEffect(() => {
    if (!window.FS) {
      return;
    }
    if (!hashedUserId || !name) {
      return;
    }
    const payload = { hashedUserId, isEntitled, marketId, marketName: name };
    window.FS.event("view market profile event", payload);
  }, [hashedUserId, isEntitled, name, marketId]);

  useEffect(() => {
    if (!marketId) {
      setHasError(true);
      setIsLoading(false);
      return;
    }
    (async () => {
      const url = `/api/v3/data/market-profile/${marketId}/overview`;
      const weightedPriorities = JSON.parse(sessionStorage.getItem(`weightedPriorities-${marketId}`) || "[]");
      const response: MarketProfileOverviewResponse = await postAsyncRequest(url, { weightedPriorities });
      if (response?.status === 201 && response.data?.data) {
        setChecklistCount(response.data.data.checklistCount || 0);
        setDescription(response.data.data.description || "");
        setFeaturesAndFunctionalities(response.data.data.featuresAndFunctionalities || []);
        setGcomCcDocCd(response.data.data.gcomCcDocCd || -1);
        setGcomCcResId(response.data.data.gcomCcResId || -1);
        setGcomMgResId(response.data.data.gcomMgResId || -1);
        setGcomMqResId(response.data.data.gcomMqResId || -1);
        setHasSelectedPriorities(weightedPriorities.length > 0);
        setLatestExistingInitId(response.data.data.templateRecentInitId || "");
        setMoreFromGartner(response.data.data.moreFromGartner || []);
        setName(response.data.data.name || "");
        setPiMarketSeoName(response.data.data.piMarketSeoName || "");
        setProductCount(response.data.data.products?.length || 0);
        setProducts(response.data.data.products || []);
        setRequirementCount(response.data.data.requirementCount || 0);
        setTemplateId(Number(marketId) || 0);
        setUserInitCount(response.data.data.userInitCount || 0);
      } else {
        setHasError(true);
      }
      setIsLoading(false);
    })();
  }, [
    marketId,
    setChecklistCount,
    setDescription,
    setFeaturesAndFunctionalities,
    setGcomCcDocCd,
    setGcomCcResId,
    setGcomMgResId,
    setGcomMqResId,
    setHasSelectedPriorities,
    setIsLoading,
    setLatestExistingInitId,
    setMoreFromGartner,
    setName,
    setPiMarketSeoName,
    setProductCount,
    setProducts,
    setRequirementCount,
    setTemplateId,
    setUserInitCount,
  ]);

  return (
    <>
      <div className={styles.container}>
        {isLoading && <Loader size={24} />}
        {!isLoading && (
          <Fragment>
            <HeaderV3Component />
            <MarketOverviewSectionComponent />
            <MarketProducts />
            <MoreFromGartner />
            <Footer />
          </Fragment>
        )}
        <SnackbarBanner isOpen={hasError} setIsOpen={setHasError} type="ERROR" isDefaultErrorMessage={true} />
      </div>
      {<GartnerFooter />}
    </>
  );
};

export default AppV2;
