const saveIndicatorStyles = {
  indicatorRoot: {
    alignItems: "center",
    display: "flex",
    float: "left",
  },
  indicatorIcon: {
    alignItems: "center",
    display: "inline-flex",
    height: "48px",
    justifyContent: "center",
    width: "48px",
  },
  spinIcon: {
    animationName: "$spinner",
    animationDuration: "2s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  "@keyframes spinner": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export { saveIndicatorStyles };
