import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import styles from "./typography.styles.module.scss";
import { TypographyBoldness, TypographyColor, TypographyElement, TypographyStyling } from "./typography.types";

type TypographyProps = {
  ariaLabel?: string;
  boldness?: TypographyBoldness;
  children: Array<ReactNode> | ReactNode | number | string;
  color?: TypographyColor;
  /** overrides the element defaulted by the "styling" prop - can be: h1-5, p, or span */
  element?: TypographyElement | "";
  rootClassName?: string;
  /** style to set by design - can be: h1-5, p1-5, or span */
  styling?: TypographyStyling;
  /** @deprecated use "styling" prop instead */
  type?: TypographyStyling | "";
};

export const TypographyComponent = ({
  ariaLabel = "",
  boldness,
  children,
  color = "carbon",
  element = "",
  rootClassName = "",
  styling = "span",
  type = "",
}: TypographyProps) => {
  const style = type || styling;
  const boldClass = boldness || (["h1", "h2", "h3", "h4", "h5"].includes(style) ? "semi" : "regular");

  const className = classNames(styles[boldClass], styles[color], style !== "span" && styles[style], rootClassName);
  const label: string | undefined = ariaLabel || undefined;
  const attributes = { "aria-label": label, className };
  const display = element || style;
  return (
    <Fragment>
      {display === "h1" && <h1 {...attributes}>{children}</h1>}
      {display === "h2" && <h2 {...attributes}>{children}</h2>}
      {display === "h3" && <h3 {...attributes}>{children}</h3>}
      {display === "h4" && <h4 {...attributes}>{children}</h4>}
      {display === "h5" && <h5 {...attributes}>{children}</h5>}
      {["p", "p1", "p2", "p3", "p4", "p5"].includes(display) && <p {...attributes}>{children}</p>}
      {display === "span" && <span {...attributes}>{children}</span>}
    </Fragment>
  );
};
