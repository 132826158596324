enum HeaderSize {
  SMALL = "small",
  LARGE = "large",
}

enum ClientEvent {
  MARKET_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED = "MARKET_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED",
  MARKET_PROFILE_PAGE_VIEWED = "MARKET_PROFILE_PAGE_VIEWED",
  MARKET_PROFILE_PRODUCT_PRIORITIES_CHANGED = "MARKET_PROFILE_PRODUCT_PRIORITIES_CHANGED",
  MARKET_PROFILE_VIEW_ACTIVE_EVAL_BUTTON_CLICKED = "MARKET_PROFILE_VIEW_ACTIVE_EVAL_BUTTON_CLICKED",
  MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED = "MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED",
  MARKET_PROFILE_POWERED_BY_MAGIC_QUADRANT_LINK_CLICKED = "MARKET_PROFILE_POWERED_BY_MAGIC_QUADRANT_LINK_CLICKED",
  MARKET_PROFILE_POWERED_BY_MARKET_GUIDE_LINK_CLICKED = "MARKET_PROFILE_POWERED_BY_MARKET_GUIDE_LINK_CLICKED",
}

enum WeightedDrawerLocation {
  MARKET_PROFILE = "MARKET_PROFILE",
}

export { HeaderSize, ClientEvent, WeightedDrawerLocation };
