import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import { FormControl, InputLabel, makeStyles, MenuItem, Select as MaterialSelect } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { selectStyles } from "./styles.js";
import { ArrowIcon } from "gx-npm-icons";
import { TypographyComponent } from "../typography/typography.component";

const propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string,
  menuHeight: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  popOverPaperClassName: PropTypes.string,
  rootClassName: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  borderRadius: PropTypes.string,
};

/**
 * create a select that is stylized for BuySmart
 * @param disabled - boolean to disable or enable component
 * @param items - array of menu items
 * @param label - placeholder when no value is chosen or label that sits on the top left of the component when a value is chosen
 * @param menuHeight - height of the menu, default is auto
 * @param name - name of the select component
 * @param onChange - handles menu onChange and passes the event and name of the select component
 * @param disabled - - boolean to disable or enable component
 * @param popOverPaperClassName - custom user class name for popOver Paper
 * @param rootClassName - custom user class name
 * @param value -  value to set select
 * @param isRequired - boolean to indicate if the field is required
 * @param borderRadius - string for border radius, default to 0.
 * @returns MUI wrapper select
 */

const Select = ({
  disabled = false,
  items = [],
  label = "",
  menuHeight = "auto",
  name = "",
  onChange,
  popOverPaperClassName = "",
  rootClassName = "",
  value = "",
  isRequired = false,
  borderRadius = "0px",
}) => {
  const [selected, setSelected] = useState("");
  const useStyles = makeStyles(() => selectStyles({ menuHeight }));
  const classes = useStyles();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleOnChange = (e) => {
    setSelected(e.target.value);
    handleEvent(onChange, e, name);
  };

  const renderMenuItems = (menuItems) => {
    return (
      Array.isArray(menuItems) &&
      menuItems.map((menuItem, index) => {
        return (
          !!menuItem && (
            <MenuItem
              classes={{
                root: "bs-select-menu-item",
                selected: "bs-selected-menu-item-selected",
              }}
              key={menuItem?.idx || index}
              value={menuItem}
            >
              {menuItem}
            </MenuItem>
          )
        );
      })
    );
  };

  return (
    <FormControl
      className={classnames(
        "bs-select-form-control",
        classes.formControl,
        disabled && "disabled",
        rootClassName,
        selected && "selected"
      )}
      fullWidth={true}
      variant="outlined"
    >
      <InputLabel className="bs-select-label">
        <TypographyComponent>{label}</TypographyComponent>

        {isRequired && <span className="red-asterisk"> *</span>}
      </InputLabel>
      <MaterialSelect
        className={"bs-select-container"}
        classes={{
          select: classnames("bs-select-menu"),
        }}
        defaultValue=""
        disabled={disabled}
        IconComponent={ArrowIcon}
        label={label}
        MenuProps={{
          anchorOrigin: {
            horizontal: "left",
            vertical: "bottom",
          },
          classes: {
            paper: `${classnames("bs-select-popover-paper", classes.menuPaper, popOverPaperClassName)}`,
          },
          getContentAnchorEl: null,
          MenuListProps: {
            className: classnames(classes.menu),
            "aria-label": label,
          },
          transformOrigin: {
            horizontal: "left",
            vertical: "top",
          },
        }}
        onChange={(e) => handleOnChange(e)}
        style={{ borderRadius: borderRadius }}
        value={selected}
      >
        {renderMenuItems(items)}
      </MaterialSelect>
    </FormControl>
  );
};

Select.propTypes = propTypes;
export default Select;
