import { PriorityWeightOption, useCaptureEventsV2 } from "gx-npm-lib";
import { DrawerCloseAction, WeightedPriority, WeightedPriorityDrawerComponent } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { MarketProfileAppContext } from "../../../app.context";
import { MarketProduct } from "../../../app.types";
import { ClientEvent, WeightedDrawerLocation } from "../../../app.constants";

const MarketWeightedPriorityDrawerComponent = () => {
  const [currentPriorities, setCurrentPriorities] = useState<WeightedPriority[]>([]);
  const [currentProducts, setCurrentProducts] = useState<MarketProduct[]>([]);
  const [url, setUrl] = useState<string>("");
  const {
    featuresAndFunctionalities,
    isWeightedPriorityDrawerOpen,
    gcomCcResId,
    products,
    templateId,
    name: templateName,
    setHasSelectedPriorities,
    setIsWeightedPriorityDrawerOpen,
    setProducts,
  } = useContext(MarketProfileAppContext);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!templateId) {
      return;
    }
    setUrl(`/api/v2/data/market-profile/${templateId}/products-ranked`);
  }, [templateId]);

  useEffect(() => {
    if (!isWeightedPriorityDrawerOpen || products.length === 0) {
      setCurrentProducts([]);
      return;
    }
    setCurrentProducts(products.map((p) => ({ ...p })));
  }, [isWeightedPriorityDrawerOpen, products]);

  useEffect(() => {
    if (!isWeightedPriorityDrawerOpen || featuresAndFunctionalities.length === 0) {
      return;
    }
    const formWeightedPriorities: WeightedPriority[] = [];
    const storedWeights = JSON.parse(sessionStorage.getItem(`weightedPriorities-${templateId}`) || "[]");
    for (const feature of featuresAndFunctionalities) {
      const { id, name } = feature;
      const weight = storedWeights.find((wp: WeightedPriority) => wp.id === id)?.weight || PriorityWeightOption.NONE;
      formWeightedPriorities.push({ id, name, weight });
    }
    setCurrentPriorities(formWeightedPriorities);
  }, [featuresAndFunctionalities, isWeightedPriorityDrawerOpen, templateId]);

  const handleClose = (
    action: DrawerCloseAction,
    updates: { priorities: WeightedPriority[]; products: MarketProduct[] } = { priorities: [], products: [] }
  ) => {
    setIsWeightedPriorityDrawerOpen(false);
    if (action === DrawerCloseAction.DISCARD_CHANGES) {
      return;
    }
    const storageWeightedPriorities = updates.priorities
      .filter((item: WeightedPriority) => item.weight !== PriorityWeightOption.NONE)
      .map((item: WeightedPriority) => ({ id: item.id, weight: item.weight }));
    sessionStorage.setItem(`weightedPriorities-${templateId}`, JSON.stringify(storageWeightedPriorities));

    const hasPriorities = updates.priorities.some((item) => item.weight !== PriorityWeightOption.NONE);
    if (window.FS && hasPriorities) {
      window.FS.event("weighted priorities save on market profile", {
        templateId,
        weightedPriorities: updates.priorities,
      });
    }
    setHasSelectedPriorities(hasPriorities);
    setProducts(updates.products.map((p) => ({ ...p })));
    captureEvents([
      {
        eventType: ClientEvent.MARKET_PROFILE_PRODUCT_PRIORITIES_CHANGED,
        metaData: {
          templateId: templateId.toString(),
          priorities: updates.priorities
            .filter((p) => p.weight !== PriorityWeightOption.NONE)
            .map((p) => p.id.toString() + ":" + p.weight),
        },
      },
    ]);
  };

  return (
    <WeightedPriorityDrawerComponent
      isOpen={isWeightedPriorityDrawerOpen}
      onClose={handleClose}
      priorities={currentPriorities}
      products={currentProducts}
      updateScoresUrl={url}
      gcomCcResId={gcomCcResId}
      templateId={templateId}
      templateName={templateName}
      drawerLocation={WeightedDrawerLocation.MARKET_PROFILE}
    />
  );
};

export default MarketWeightedPriorityDrawerComponent;
