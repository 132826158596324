import classNames from "classnames";
import { MQTemplateResearchIcon } from "gx-npm-icons";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import React from "react";
import classes from "./magic-q-body.styles.module.scss";
import { useTranslation } from "react-i18next";
import { colorPalette } from "gx-npm-common-styles";

const MagicQBody = ({ documentQuery = -1, templateName = "" }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.contentBodyMidSectionLeft)}>
      <TypographyComponent rootClassName={classNames(classes.contentBodyMidSectionTitleText)} styling={"h5"}>
        <MQTemplateResearchIcon
          fill={colorPalette.interactions.berry.hex}
          stroke={colorPalette.interactions.berry.hex}
        />
        {t("Magic Quadrant")}
      </TypographyComponent>
      <TypographyComponent
        rootClassName={classNames(classes.contentBodyMidSectionDescText)}
        color={"quartz"}
        element={"p"}
      >
        {t(
          "Magic Quadrants (MQs) offer visual snapshots and in-depth analyses that provide insight into a market's direction, maturity and participants. They help you:"
        )}
      </TypographyComponent>
      <ul className={classNames(classes.contentBodyMidSectionList)}>
        <li className={classNames(classes.contentBodyMidSectionLeftListItem)}>
          {t("Map provider strengths and challenges against specific business requirements.")}
        </li>
        <li className={classNames(classes.contentBodyMidSectionLeftListItem)}>
          {t(
            "Gain fast insight into market imperatives and providers’ ability to deliver on what you require today and in the future."
          )}
        </li>
        <li className={classNames(classes.contentBodyMidSectionLeftListItem)}>
          {t(
            "Understand how a market’s providers are competitively positioned, and the strategies they are using to compete."
          )}
        </li>
      </ul>
      <div className={classNames(classes.contentLearnMoreLine)} />
      <TypographyComponent
        element={"p"}
        rootClassName={classNames(classes.contentBodyMidSectionLearnMore)}
        color={"stone"}
      >
        {t("Learn more")}
      </TypographyComponent>
      <div className={classNames(classes.contentLink)}>
        <TextLink
          rootClassName={classNames(classes.contentBodyMidSectionLinkText)}
          href={`https://www.gartner.com/document/${documentQuery}`}
          target="_blank"
          text={t(`Magic Quadrant for ${templateName}`)}
          variant={"dark"}
        />
      </div>
    </div>
  );
};

export default MagicQBody;
