// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c13cJ38lUkoUgOFiRd48{margin-left:76px;min-height:calc(100vh - 128px)}`, "",{"version":3,"sources":["webpack://./src/app.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,8BAAA","sourcesContent":[".container {\n  margin-left: 76px;\n  min-height: calc(100vh - 128px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `c13cJ38lUkoUgOFiRd48`
};
export default ___CSS_LOADER_EXPORT___;
