// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l3O9wYBBBIL4Le0oInSg{padding-right:81px}.l3O9wYBBBIL4Le0oInSg .mBSvRRf7bRlLj4uWnCJW{padding-bottom:56px}.l3O9wYBBBIL4Le0oInSg .v_pREIx3ZjSFdkCcjDfg{display:flex;align-items:center;gap:15px;padding-bottom:12px}.l3O9wYBBBIL4Le0oInSg .xmkOw8DhmbujHBq_8g6B{color:var(--color-neutrals-quartz);width:400px}.l3O9wYBBBIL4Le0oInSg .I3eTkBWQsSUucwnM0jwA{width:400px;margin-bottom:56px}.l3O9wYBBBIL4Le0oInSg .hRELHqc_vbADkSg_nETM{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.l3O9wYBBBIL4Le0oInSg .aOS5K6zNi9EoCFueSoKT{margin-bottom:16px}.l3O9wYBBBIL4Le0oInSg .Sn0gpsLE1ZbArQhteihf .gZ11HdxqrtDH4LGz3Ose{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,kEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `l3O9wYBBBIL4Le0oInSg`,
	"gx-gartner-mg-desc-text": `mBSvRRf7bRlLj4uWnCJW`,
	"contentBodyMidSectionTitleText": `v_pREIx3ZjSFdkCcjDfg`,
	"contentBodyMidSectionDescText": `xmkOw8DhmbujHBq_8g6B`,
	"contentBodyMidSectionDescTextGCOM3523": `I3eTkBWQsSUucwnM0jwA`,
	"contentLearnMoreLine": `hRELHqc_vbADkSg_nETM`,
	"contentBodyMidSectionLearnMore": `aOS5K6zNi9EoCFueSoKT`,
	"contentLink": `Sn0gpsLE1ZbArQhteihf`,
	"contentBodyMidSectionLinkText": `gZ11HdxqrtDH4LGz3Ose`
};
export default ___CSS_LOADER_EXPORT___;
