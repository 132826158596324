import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbonHex = colorPalette.neutrals.carbon.hex;
const colorCoalHex = colorPalette.neutrals.coal.hex;
const colorIronHex = colorPalette.neutrals.iron.hex;
const colorMildBerryHex = colorPalette.interactions.mildBerry.hex;
const weightRegularAmount = weightPalette.regular.amount;
const weightRegularVariation = weightPalette.regular.variation;
const xDatePickerDefaultWidth = 180; // This needs to be overridden from rootClasses
const defaultCta = colorPalette.interactions.defaultCta.hex;
const hoverCta = colorPalette.interactions.hoverCta.hex;

const xDatePickerClasses = {
  xDatePicker: {
    width: xDatePickerDefaultWidth,
    borderRadius: 2,
    "& button": {
      "& svg": {
        display: "none",
      },
      position: "absolute",
      top: 2,
      left: 2,
      right: 2,
      bottom: 2,
      borderRadius: 2,
      width: xDatePickerDefaultWidth,
    },
    "& input": {
      color: colorCarbonHex,
      fontSize: "14px",
      fontVariationSettings: weightRegularVariation,
      fontWeight: weightRegularAmount,
    },
    "&.show-icon": {
      width: "100%",
      "& button": {
        borderRadius: 4,
        display: "block",
        padding: "12px",
        "& svg": {
          display: "block",
        },
      },
      "& input": {
        paddingLeft: "54px",
      },
    },
  },
  xDateContainer: {
    color: colorPalette.neutrals.carbon.hex,
    display: "flex",
    fontSize: "14px",
    fontVariationSettings: weightRegularVariation,
    fontWeight: weightRegularAmount,
    alignItems: "center",
    "& button": {
      marginRight: 8,
    },
    "& span": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  xDuration: {
    color: colorIronHex,
  },
  xToolBar: {
    padding: "16px 24px 16px 24px",
    backgroundColor: colorMildBerryHex,
  },
  xToolBarTitle: { color: colorCoalHex, marginBottom: 6 },
  xToolBarRange: {
    fontSize: 28,
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    lineHeight: "36.4px",
    letterSpacing: "0.15px",
    color: colorCarbonHex,
  },
  xActionBar: {
    gridRow: 3,
    gridColumn: 2,
    borderTop: "1px solid" + colorPalette.neutrals.silver.hex,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& .mini-btn": {
      color: colorPalette.neutrals.iron.hex,
    },
    "& .mini-btn-enabled": {
      color: defaultCta,
      "& path": {
        fill: defaultCta,
      },
    },
  },
  xDateRangePickerActionBar: {
    gridColumn: 1,
  },
};
const xDatePickerStyles = {
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation: {
          border: "2px solid " + colorPalette.neutrals.silver.hex,
          borderRadius: "4px",
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: defaultCta,
              borderWidth: 2,
            },
            "&:hover": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: hoverCta,
              },
            },
          },
          "&.Mui-disabled": {
            fieldset: {
              border: 0,
            },
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: colorPalette.neutrals.coal.hex,
          fontSize: 16,
          fontWeight: weightPalette.medium.amount,
          borderRadius: 4,
          "&:hover": {
            backgroundColor: colorPalette.neutrals.silver.hex,
          },
          "&:focus": {
            backgroundColor: colorPalette.basic.white.hex,
          },
          "&.Mui-selected": {
            backgroundColor: defaultCta + " !important", // this takes highest priority
          },
          "&.MuiPickersDay-today": {
            borderColor: colorPalette.neutrals.coal.hex,
          },
          "&.MuiPickersDay-dayWithMargin": {
            borderRadius: 4,
          },
        },
      },
    },
  },
};
const xDateRangePickerStyles = {
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: "none",
          marginTop: "4px",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: colorPalette.neutrals.coal.hex,
          fontSize: 16,
          fontWeight: weightPalette.medium.amount,
          "&": {
            borderRadius: 4,
          },
          "&:hover": {
            backgroundColor: colorPalette.neutrals.silver.hex,
          },
          "&:focus": {
            backgroundColor: colorPalette.basic.white.hex,
          },
          "&.Mui-selected": {
            backgroundColor: defaultCta,
          },
          "&.Mui-selected-start": {
            backgroundColor: defaultCta + " !important", // this takes highest priority
            border: 0,
            margin: 0,
            color: "white",
            width: 40,
            borderRadius: 0,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            "&.MuiPickersDay-today": {
              border: 0,
              borderRadius: 0,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            },
          },
          "&.Mui-selected-end": {
            backgroundColor: defaultCta + " !important", // this takes highest priority
            border: 0,
            margin: 0,
            width: 40,
            color: "white",
            borderRadius: 0,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            "&.MuiPickersDay-today": {
              border: 0,
              borderRadius: 0,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            },
          },
          "&.MuiPickersDay-today": {
            border: `1px solid ${colorPalette.neutrals.coal.hex}`,
            borderRadius: 4,
            "&.MuiPickersDay-range": {
              span: {
                border: `1px solid ${colorPalette.neutrals.coal.hex}`,
                borderRadius: 4,
              },
            },
            "&.MuiPickersDay-range-hover": {
              border: `1px solid ${colorPalette.neutrals.coal.hex}`,
            },
          },
          "&.MuiPickersDay-range": {
            backgroundColor: colorPalette.interactions.lightBerry.hex,
            borderRadius: 0,
            border: 0,
            width: 40,
            margin: 0,
          },
          "&.MuiPickersDay-range-hover": {
            backgroundColor: colorPalette.neutrals.quartz.hex,
            borderRadius: 0,
            width: 40,
            margin: 0,
            border: 0,
          },
        },
      },
    },
  },
};

export { xDatePickerStyles, xDateRangePickerStyles, xDatePickerClasses };
