// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.epE0fS1ePhgkFSU0FkP9{margin-top:5px;cursor:default;width:80px}.epE0fS1ePhgkFSU0FkP9 p{margin-top:4px;color:var(--color-neutrals-iron)}`, "",{"version":3,"sources":["webpack://./src/sections/market-products/product-row/cells/magic-quadrant-cell/magic-quadrant-cell.styles.module.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CACA,cAAA,CACA,UAAA,CAGF,wBACE,cAAA,CACA,gCAAA","sourcesContent":["\n.wrapper {\n  margin-top: 5px;\n  cursor: default;\n  width: 80px ;\n}\n\n.wrapper p{\n  margin-top: 4px;\n  color: var(--color-neutrals-iron);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `epE0fS1ePhgkFSU0FkP9`
};
export default ___CSS_LOADER_EXPORT___;
