import { LinearProgressBar } from "gx-npm-ui";
import { Fragment } from "react";

type ProductFitBarProps = { fitScore: number; productName: string; totalProducts: number };
const ProductFitBarComponent = ({ fitScore = 0, productName = "", totalProducts = 0 }: ProductFitBarProps) => {
  return (
    <Fragment>
      {fitScore > 0 && (
        <LinearProgressBar
          label={`${productName} has a score of ${fitScore} out of ${totalProducts} vendors`}
          score={fitScore}
        />
      )}
    </Fragment>
  );
};

export default ProductFitBarComponent;
