import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { RestoreArchivedFolderIcon, RestoreDeletedTrashcanIcon } from "gx-npm-icons";
import { handleEvent, InitState, isValidDate } from "gx-npm-lib";
import PipeSvg from "./pipeSvg";
import { restoreBannerStyles as styles } from "./styles";
import { TypographyComponent } from "../typography/typography.component";
import { colorPalette } from "gx-npm-common-styles";

const formatDate = (date) => {
  let display = "";
  if (isValidDate(date)) {
    const day = "numeric";
    const month = "long";
    const timeZone = "UTC";
    const year = "numeric";
    const options = { day, month, timeZone, year };
    display = new Date(date).toLocaleString("en-us", options);
  }
  return display;
};

const bannerCopy = {
  button: {
    [InitState.ARCHIVED]: "Unarchive",
    [InitState.DELETED]: "Restore from trash",
  },
  date: {
    [InitState.ARCHIVED]: "Archived",
    [InitState.DELETED]: "Deleted",
  },
};

const propTypes = {
  actionDate: PropTypes.string,
  initState: PropTypes.oneOf([InitState.ARCHIVED, InitState.DELETED]),
  isRestorable: PropTypes.bool,
  onClickAction: PropTypes.func,
};

const useStyles = makeStyles(() => styles);

const RestoreAlertBanner = ({
  actionDate = "",
  initState = InitState.DELETED,
  isRestorable = false,
  onClickAction = () => {},
}) => {
  const displayDate = formatDate(actionDate);
  const classes = useStyles();
  return (
    <section aria-label="restore evaluation banner" className={classNames(classes.restoreBannerRoot)}>
      <div className={classNames(classes.restoreBannerWrapper)}>
        <div className={classNames(classes.bannerDate)}>
          <TypographyComponent color={"coal"} boldness={"medium"} styling={"p3"}>
            {bannerCopy.date[initState]}
            {displayDate && ` on ${displayDate}`}
          </TypographyComponent>
        </div>
        {isRestorable && (
          <div className={classNames(classes.bannerButtonContainer)}>
            <span className={classNames(classes.pipeSvg)}>
              <PipeSvg />
            </span>
            <div
              className={classNames(classes.bannerRestoreButton)}
              onClick={() => handleEvent(onClickAction)}
              onKeyDown={(e) => e.key === "Enter" && handleEvent(onClickAction)}
              role="button"
              tabIndex={0}
              aria-label="Restore Button"
            >
              <TypographyComponent rootClassName={classNames(classes.textButton)} boldness={"medium"} styling={"p3"}>
                {initState === InitState.ARCHIVED && (
                  <RestoreArchivedFolderIcon fillPath={colorPalette.interactions.defaultCta.hex} />
                )}
                {initState === InitState.DELETED && (
                  <RestoreDeletedTrashcanIcon fillPath={colorPalette.interactions.defaultCta.hex} />
                )}
                {bannerCopy.button[initState]}
              </TypographyComponent>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

RestoreAlertBanner.propTypes = propTypes;
export default RestoreAlertBanner;
