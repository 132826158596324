import React from "react";
import { useTranslation } from "react-i18next";
import { MQChallengersIcon, MQLeadersIcon, MQNichePlayersIcon, MQVisionariesIcon } from "gx-npm-icons";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import styles from "./magic-quadrant-cell.styles.module.scss";
import classNames from "classnames";

const mqTooltips = {
  Challenger: `Challengers have a strong current offering but may not have a plan to maintain this. Vendors in mature markets may be positioned as Challengers because they choose to avoid disrupting established customers or may lack strong vision, innovation, or an overall understanding of market needs.`,
  Leader: `Leaders provide mature offerings to meet wide market demand and invest to sustain their position. They typically have large, satisfied customer bases (relative to the size of the market) and should be able to remain viable in a challenging economy.`,
  "Niche Player": `Niche Players may specialize on a particular functionality, market segment, industry sector or region, or they may have a limited ability to innovate or outperform others in the market.`,
  Visionary: `Visionaries fall into the higher-risk-higher-reward category and align with how a market will evolve. They often introduce new technology, services, or business models, but could be building financial clout and operational capabilities.`,
};
const mqPositions = ["Challenger", "Leader", "Niche Player", "Visionary"];

type MagicQuadrantsCellProps = {
  mqPosition: string;
};
const MagicQuadrantsCell = ({ mqPosition = "" }: MagicQuadrantsCellProps) => {
  const { t } = useTranslation();
  const ariaLabel = mqPosition ? t("Magic Quadrant position") : t("no Magic Quadrant position");
  const popperProps = { modifiers: { offset: { offset: "0px, 10px" } } };
  const subtitle = mqPositions.indexOf(mqPosition) === -1 ? "" : mqPosition;

  const mqIcons = {
    Challenger: <MQChallengersIcon />,
    Leader: <MQLeadersIcon />,
    "Niche Player": <MQNichePlayersIcon />,
    Visionary: <MQVisionariesIcon />,
  };

  return (
    <div>
      <TooltipV2
        placement="left"
        PopperProps={popperProps}
        title={
          t(mqTooltips[mqPosition as keyof typeof mqTooltips]) ||
          t("This vendor is not positioned in the Magic Quadrant for this technology.")
        }
      >
        <div aria-label={ariaLabel} className={classNames(styles.wrapper)}>
          {mqIcons[mqPosition as keyof typeof mqIcons]}
          {!mqIcons[mqPosition as keyof typeof mqIcons] && (
            <TypographyComponent boldness={"medium"} styling={"p4"}>
              {`-`}
            </TypographyComponent>
          )}
          <TypographyComponent boldness="medium" styling="p4">
            {subtitle || t("None")}
          </TypographyComponent>
        </div>
      </TooltipV2>
    </div>
  );
};
export default MagicQuadrantsCell;
