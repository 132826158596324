import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../../app.context";
import { TypographyComponent } from "gx-npm-ui";
import styles from "./market-features-table-header.styles.module.scss";
import classNames from "classnames";

const MarketFeaturesTableHeaderComponent = () => {
  const { t } = useTranslation();
  const { featuresAndFunctionalities } = useContext(MarketProfileAppContext);

  return (
    <thead className={classNames(styles.tableHeader)}>
      <tr className={styles.tableHeaderRow}>
        <th className={classNames(styles.tableHeaderCell)}>
          <TypographyComponent styling="p4" boldness="medium" rootClassName={styles.tableHeaderTitle}>
            {t("Features and functionality")}
            {featuresAndFunctionalities.length > 0 && ` (${featuresAndFunctionalities.length})`}
          </TypographyComponent>
        </th>
        <th className={classNames(styles.tableHeaderCell)}>
          <TypographyComponent styling="p4" boldness="medium" rootClassName={styles.tableHeaderTitle}>
            {t("Top vendor")}
          </TypographyComponent>
        </th>
      </tr>
    </thead>
  );
};

export default MarketFeaturesTableHeaderComponent;
