import classNames from "classnames";
import { useUserState } from "gx-npm-lib";
import styles from "./market-features-table.styles.module.scss";
import MarketFeaturesEntitledTableComponent from "./market-features-entitled-table/market-features-entitled-table.component";
import MarketFeaturesUnentitledTableComponent from "./market-features-unentitled-table/market-features-unentitled-table.component";

const MarketFeaturesTableComponent = () => {
  const { freeTrialRole, isEntitled } = useUserState();
  const canViewEntitledFeatures = !!freeTrialRole || isEntitled;
  return (
    <div className={classNames(styles.tableContentWrapper, !canViewEntitledFeatures && styles.isNotEntitled)}>
      {canViewEntitledFeatures && <MarketFeaturesEntitledTableComponent />}
      {!canViewEntitledFeatures && <MarketFeaturesUnentitledTableComponent />}
    </div>
  );
};

export default MarketFeaturesTableComponent;
