import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./linear-progress-bar.styles.module.scss";
import classNames from "classnames";
import { LinearProgressBarTypes } from "./linear-progress-bar.types";

const LinearProgressBar: React.FC<LinearProgressBarTypes> = ({ score = 0, label = "" }) => {
  const classes = {
    bar: styles.linearProgressBar,
    colorPrimary: styles.linearProgressColorPrimary,
    root: styles.linearProgressRoot,
  };
  return (
    <div className={classNames(styles.root)}>
      <LinearProgress aria-label={label} classes={classes} value={score} variant="determinate" />
    </div>
  );
};

export default LinearProgressBar;
