import React from "react";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider, FeatureFlagsAvailableContainer } from "gx-npm-ui";
import { MarketProfileAppContextProvider } from "./app.context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HandleRedirects from "./handle-redirects";
import AppV2 from "./app-v2.component";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <FeatureFlagsAvailableContainer>
        <MarketProfileAppContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path={"/s/market/:marketId/template-preview"} element={<AppV2 />} />
              <Route path={"/s/market/:marketId/preview"} element={<AppV2 />} />
              <Route path={"/s/market/:marketId"} element={<AppV2 />} />
              <Route path={"*"} element={<HandleRedirects />} />
            </Routes>
          </BrowserRouter>
        </MarketProfileAppContextProvider>
      </FeatureFlagsAvailableContainer>
    </FeatureFlagProvider>
  );
};

export default Root;
