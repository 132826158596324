// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aj7ALTF8DeYBKXELoigw .gx-dialog-body{margin-left:-36px;width:600px}.ImiUtosM_c_Qnpwk5Qbl{padding:24px 36px 56px 36px}.MVqfq5yfzGoaBHInMP21{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.MVqfq5yfzGoaBHInMP21 .XOYaTYQje1QKbuyAyrV2{display:block}.MVqfq5yfzGoaBHInMP21 .RN68lqLukBtlflN6FU3A{position:relative}.MVqfq5yfzGoaBHInMP21 .RN68lqLukBtlflN6FU3A .q2cpkVVNENBWjWtQQgct{bottom:-74px;position:absolute;right:0}.kY6T1jrhsPBO6iaY4Rt8{align-items:center;display:flex;justify-content:flex-end}.kY6T1jrhsPBO6iaY4Rt8 .zpcTFnMFNUgsb_lweM9n{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `aj7ALTF8DeYBKXELoigw`,
	"subtitleParagraph": `ImiUtosM_c_Qnpwk5Qbl`,
	"remainingInitsHeading": `MVqfq5yfzGoaBHInMP21`,
	"headingSecondLine": `XOYaTYQje1QKbuyAyrV2`,
	"cornerImgContainer": `RN68lqLukBtlflN6FU3A`,
	"cornerImg": `q2cpkVVNENBWjWtQQgct`,
	"footerButtonContainer": `kY6T1jrhsPBO6iaY4Rt8`,
	"cancelButton": `zpcTFnMFNUgsb_lweM9n`
};
export default ___CSS_LOADER_EXPORT___;
