import { Collapse } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../../../app.context";
import MarketFeaturesTableHeaderComponent from "../market-features-table-header.component";
import ExpandCollapseFeaturesButtonComponent from "./expand-collapse-features-button/expand-collapse-features-button.component";
import MarketFeaturesTableRowComponent from "./market-features-table-row.component";
import styles from "./market-features-entitled-table.styles.module.scss";

const SESSION_MARKET_FEATURES_EXPANDED_KEY = "marketFeaturesListExpanded";
const MIN_TABLE_ROWS_SHOWN = 5;
const MarketFeaturesEntitledTableComponent = () => {
  const { t } = useTranslation();
  const { featuresAndFunctionalities, templateId } = useContext(MarketProfileAppContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (!templateId) {
      return;
    }
    const sessionStorageExpanded = sessionStorage.getItem(`${SESSION_MARKET_FEATURES_EXPANDED_KEY}-${templateId}`);
    setIsExpanded(sessionStorageExpanded === "true");
  }, [templateId]);

  const handleClickExpandCollapse = (expand: boolean) => {
    setIsExpanded(expand);
    sessionStorage.setItem(`${SESSION_MARKET_FEATURES_EXPANDED_KEY}-${templateId}`, expand.toString());
  };

  return (
    <Fragment>
      <table cellSpacing="0" className={styles.tableContainer}>
        <MarketFeaturesTableHeaderComponent />
        <tbody>
          {featuresAndFunctionalities.slice(0, MIN_TABLE_ROWS_SHOWN).map((featFunc) => {
            return (
              <MarketFeaturesTableRowComponent
                key={featFunc.name}
                name={featFunc.name}
                description={featFunc.description}
                topProducts={featFunc.topProducts}
              />
            );
          })}
        </tbody>
      </table>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <table cellSpacing="0" className={styles.tableContainer} aria-label={t("collapsible table section")}>
          <tbody>
            {featuresAndFunctionalities.slice(MIN_TABLE_ROWS_SHOWN).map((featFunc) => {
              return (
                <MarketFeaturesTableRowComponent
                  key={featFunc.name}
                  name={featFunc.name}
                  description={featFunc.description}
                  topProducts={featFunc.topProducts}
                />
              );
            })}
          </tbody>
        </table>
      </Collapse>
      {featuresAndFunctionalities.length > MIN_TABLE_ROWS_SHOWN && (
        <ExpandCollapseFeaturesButtonComponent isExpanded={isExpanded} onClick={handleClickExpandCollapse} />
      )}
    </Fragment>
  );
};

export default MarketFeaturesEntitledTableComponent;
