import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { Portal } from "@material-ui/core";
import { createGenerateClassName, makeStyles, StylesProvider } from "@material-ui/core/styles";
import { colorPalette } from "gx-npm-common-styles";
import { getRnd, handleEvent } from "gx-npm-lib";
import { MenuOptionList } from "./menu-option-list.component";
import MenuSelectedOption from "./menuSelectedOption";
import PopoverMenuContent from "./popoverMenuContent";
import { rootPopoverStyles as styles } from "./styles";
import { alignmentTypes, iconTypes, variantTypes } from "./types";
import { TypographyComponent } from "../typography/typography.component";

const generateClassName = createGenerateClassName({
  seed: "PopoverMenu-UI" + uuidV4(),
});

const propTypes = {
  children: PropTypes.any, // ReactNode | ReactNode[] | string
  disabled: PropTypes.bool,
  handleOpenStatus: PropTypes.func,
  helperText: PropTypes.any, // ReactNode | string
  iconHoverColor: PropTypes.string,
  iconHoverFill: PropTypes.string,
  iconSelectedColor: PropTypes.string,
  iconType: PropTypes.oneOf(["", ...Object.values(iconTypes)]),
  isBehindAppBar: PropTypes.bool,
  isClosedOnScroll: PropTypes.bool,
  alignmentType: PropTypes.oneOf(["", ...Object.values(alignmentTypes)]),
  listFooter: PropTypes.node,
  listHeader: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      icon: PropTypes.node,
      menuListIcon: PropTypes.node,
      index: PropTypes.number,
      name: PropTypes.any, // ReactNode | string
      selectedClassName: PropTypes.string,
      showSelectedIndex: PropTypes.bool,
    })
  ),
  noCurrentSelectionText: PropTypes.string,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
  selectedIndex: PropTypes.number,
  showChildren: PropTypes.bool,
  showSelectedOption: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipOptions: PropTypes.object,
  useIconButton: PropTypes.bool,
  usePortal: PropTypes.bool,
  variant: PropTypes.oneOf(["", ...Object.values(variantTypes)]),
  listItemClassName: PropTypes.string,
  iconButtonBorderRadius: PropTypes.number,
  popoverMenuClass: PropTypes.string,
  isDynamicWidth: PropTypes.bool,
  titleText: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const PopoverMenu = ({
  children,
  disabled = false,
  handleOpenStatus = (..._args) => {},
  helperText = "",
  iconHoverColor = "",
  iconHoverFill = colorPalette.neutrals.coal.hex,
  iconSelectedColor = "",
  iconType = iconTypes.arrow,
  isClosedOnScroll = false,
  isBehindAppBar = false,
  alignmentType = alignmentTypes.default,
  listFooter = null,
  listHeader = null,
  menuItems = [],
  noCurrentSelectionText = "",
  onClick = (..._args) => {},
  rootClassName = "",
  selectedIndex = -1,
  showChildren = false,
  showSelectedOption = true,
  showTooltip = false,
  tooltipOptions = {},
  useIconButton = false,
  usePortal = false,
  variant = "",
  listItemClassName = "",
  iconButtonBorderRadius = 4,
  popoverMenuClass = "",
  isDynamicWidth = true,
  titleText = "",
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [uniqueClass, setUniqueClass] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const popoverContainer = useRef(null);
  const hiddenMenuItemRef = useRef(null);
  const [hiddenMenuItemWidth, setHiddenItemWidth] = useState(0);
  const borderWidth = 4;
  const minPopoverMenuWidth = 112;

  useEffect(() => {
    const uniqueContainerClass = "unique-popover-" + getRnd(100, 65536);
    setUniqueClass(uniqueContainerClass);
  }, []);

  useEffect(() => {
    if (!isClosedOnScroll) {
      return;
    }
    const scrollCallback = () => {
      setIsOpen(false);
    };
    window.addEventListener("wheel", scrollCallback);
    return () => {
      window.removeEventListener("wheel", scrollCallback);
    };
  }, [isClosedOnScroll]);

  useEffect(() => {
    if (!isClosedOnScroll) {
      return;
    }
    const scrollCallback = () => {
      setIsOpen(false);
    };
    window.addEventListener("scroll", scrollCallback);
    return () => {
      window.removeEventListener("scroll", scrollCallback);
    };
  }, [isClosedOnScroll]);

  useEffect(() => {
    handleEvent(handleOpenStatus, isOpen);
    if (isOpen) {
      setIsHovering(true);
    }
  }, [handleOpenStatus, isOpen]);

  useEffect(() => {
    if (!hiddenMenuItemRef.current) {
      return;
    }
    const boundingClientRect = hiddenMenuItemRef.current.getBoundingClientRect();

    if (!boundingClientRect || !boundingClientRect.width || boundingClientRect.width < minPopoverMenuWidth) {
      return;
    }
    setHiddenItemWidth(boundingClientRect.width);
  }, [menuItems]);

  const handleKeyDown = (event) => {
    if (!disabled) {
      const keyValue = event?.key?.toLocaleLowerCase();
      if (keyValue === "escape") {
        setIsOpen(false);
      } else if (keyValue === "enter") {
        setIsOpen(true);
      }
    }
  };

  const handleNonOptionsClick = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  // only handling clicks of valid index menu items
  const handleOptionsClick = (event) => {
    if (typeof event !== "object") {
      handleEvent(onClick, event);
      setIsHovering(false);
    }
  };

  const handleMouseEvent = (type) => {
    setIsHovering("enter" === type || "move" === type);
  };

  const handleMenuOptionList = (el) => {
    const child = el?.children?.[0] || null;
    if (child) {
      const childRect = child.getBoundingClientRect?.() || null;
      if (!usePortal) {
        const isYPosOffScreen = childRect?.y + childRect?.height > window.innerHeight;
        if (isYPosOffScreen) {
          child.style.top = -(childRect.height + 8) + "px";
        }
      } else {
        const popoverContainerCurr = popoverContainer?.current;
        const popoverContainerRect = popoverContainerCurr.getBoundingClientRect?.() || null;
        if (alignmentType === alignmentTypes.leftBottom) {
          child.style.top = popoverContainerRect?.y + window.pageYOffset + popoverContainerRect.height + 4 + "px";
        } else {
          child.style.top = popoverContainerRect?.y + window.pageYOffset - childRect?.height - 8 + "px";
        }
        child.style.left = popoverContainerRect?.x - childRect?.width + popoverContainerRect?.width + "px";
      }
    }
  };

  function findLongestMenuItem(arr) {
    return arr?.reduce((longest, current) => {
      return current.name.length > longest.length ? current.name : longest;
    }, "");
  }

  const longestMenuItemName = findLongestMenuItem(menuItems);
  return (
    <StylesProvider generateClassName={generateClassName}>
      <div
        style={{
          ...(showSelectedOption && isDynamicWidth && { width: hiddenMenuItemWidth + borderWidth }),
        }}
        className={classNames(
          "gx-popover-menu-root",
          classes.menuRoot,
          disabled && "disabled",
          isOpen && "open",
          rootClassName,
          uniqueClass,
          variantTypes[variant] && variant,
          "text-box-rounded",
          !showSelectedOption && "no-show-selected-option"
        )}
        data-testid={"popover-menu"}
        onClick={handleNonOptionsClick}
        onKeyDown={handleKeyDown}
        ref={popoverContainer}
        role="button"
        tabIndex={0}
        onBlur={() => handleMouseEvent("blur")}
        onMouseEnter={() => handleMouseEvent("enter")}
        onMouseMove={() => handleMouseEvent("move")}
        onMouseOut={() => handleMouseEvent("out")}
      >
        {/*todo this is breaking accessibility rules, will need addressing*/}
        {isDynamicWidth && showSelectedOption && (
          <div ref={hiddenMenuItemRef} className={classNames(classes.hiddenMenuItem, "gx-hidden-menu-item")}>
            <div>{longestMenuItemName}</div>
          </div>
        )}
        {showSelectedOption && (
          <MenuSelectedOption
            disabled={disabled}
            menuItems={menuItems}
            noCurrentSelectionText={noCurrentSelectionText}
            selectedIndex={selectedIndex}
            variant={variantTypes[variant] && variant}
          />
        )}
        {!showSelectedOption && titleText && (
          <TypographyComponent boldness={"medium"} element={"p"} rootClassName={classes.titleText}>
            {titleText}
          </TypographyComponent>
        )}
        {showChildren && children}
        {!showChildren && (
          <PopoverMenuContent
            disabled={disabled}
            iconHoverColor={iconHoverColor}
            iconHoverFill={iconHoverFill}
            iconSelectedColor={iconSelectedColor}
            iconType={iconType}
            isHovering={isHovering}
            isOpen={isOpen}
            showTooltip={showTooltip}
            tooltipOptions={tooltipOptions}
            useIconButton={useIconButton}
            variant={variantTypes[variant] && variant}
            iconButtonBorderRadius={iconButtonBorderRadius}
          />
        )}
        {isOpen && (
          <Portal disablePortal={!usePortal}>
            <MenuOptionList
              alignmentType={alignmentType}
              containerClass={uniqueClass}
              isBehindAppBar={isBehindAppBar}
              listFooter={listFooter}
              listHeader={listHeader}
              listItemClassName={listItemClassName}
              menuItems={menuItems}
              onNonOptionsClick={handleNonOptionsClick}
              onOptionsClick={handleOptionsClick}
              popoverMenuClass={popoverMenuClass}
              setRef={handleMenuOptionList}
              variant={variantTypes[variant] && variant}
              isDynamicWidth={isDynamicWidth}
            />
          </Portal>
        )}
        {helperText && <label className={classNames(classes.helperText)}>{helperText}</label>}
      </div>
    </StylesProvider>
  );
};

PopoverMenu.propTypes = propTypes;
export { PopoverMenu };
