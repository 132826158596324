import classNames from "classnames";
import { DownloadOnTheAppStoreBadgeIcon, GetItOnGooglePlayBadgeIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "../typography/typography.component";
import styles from "./gartner-footer.styles.module.scss";

export const GartnerFooter = ({ hasNavbar = true }: { hasNavbar?: boolean }) => {
  const { t } = useTranslation();
  return (
    <footer className={classNames(styles.footer, hasNavbar && styles.windowWithNavbar)}>
      <div className={styles.footerContent}>
        <div>
          <div className={styles.garterLinkGrouping}>
            <a href="https://www.gartner.com/en/about/policies/overview">
              <TypographyComponent boldness="medium" color="slate" rootClassName={styles.link} styling="p4">
                {t("POLICIES")}
              </TypographyComponent>
            </a>
            <a href="https://www.gartner.com/en/about/policies/privacy">
              <TypographyComponent boldness="medium" color="slate" rootClassName={styles.link} styling="p4">
                {t("PRIVACY POLICY")}
              </TypographyComponent>
            </a>
            <a href="https://www.gartner.com/en/about/policies/terms-of-use">
              <TypographyComponent boldness="medium" color="slate" rootClassName={styles.link} styling="p4">
                {t("TERMS OF USE")}
              </TypographyComponent>
            </a>
            <a href="https://www.gartner.com/en/about/ombuds">
              <TypographyComponent boldness="medium" color="slate" rootClassName={styles.link} styling="p4">
                {t("OMBUDS")}
              </TypographyComponent>
            </a>
          </div>
          <div className={styles.copyright}>
            <TypographyComponent color="slate" element="span" styling="p4">
              &copy; {t("2024 Gartner, Inc. and/or its affiliates. All rights reserved.")}
            </TypographyComponent>
          </div>
        </div>
        <div className={styles.appLinkGrouping}>
          <TypographyComponent color="gartnerBlue" rootClassName={styles.title} styling="h5">
            {t("Get the App")}
          </TypographyComponent>
          <div className={styles.badges}>
            <a
              aria-label={t("Get the Gartner App on Google play")}
              href="https://play.google.com/store/apps/details?id=com.gartner.mygartner&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <GetItOnGooglePlayBadgeIcon />
            </a>
            <a
              aria-label={t("Get the Gartner App on Apple store")}
              href={"https://apps.apple.com/us/app/my-gartner-mobile/id1202220943?itsct=apps_box_link&itscg=30200"}
            >
              <DownloadOnTheAppStoreBadgeIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
