const reportFullStoryTemplateCreateEvent = (config = { templateId: 0, templateName: "" }) => {
  if (!window.FS) {
    return;
  }
  const templateHubSessionData = sessionStorage.getItem("template_hub_session");
  const searchBrowseData: {
    isSearchData: boolean;
    searchResultsCount: number;
    searchTerm: string;
    categoryId: number;
    categoryName: string;
  } = JSON.parse(templateHubSessionData || "{}");

  const { templateId, templateName } = config;
  const payload = {
    Mode: searchBrowseData.isSearchData ? "Search" : "Browse",
    "Search Results Count": searchBrowseData.searchResultsCount || 0,
    "Search Term": searchBrowseData.searchTerm || "",
    "Template Category ID": searchBrowseData.categoryId,
    "Template Category Name": searchBrowseData.categoryName,
    "Template ID": templateId,
    "Template Name": templateName,
  };
  window.FS.event("use template btn click", payload);
};

export { reportFullStoryTemplateCreateEvent };
