// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L_SDTp0zWDAjJcwArOk9{display:flex}.K4SlIkA74LNQHY_V2qO1 .KYYkk3_wS1HB2sSAXAdZ{border-bottom:1px solid var(--color-neutrals-silver);padding-bottom:8px;text-align:left;width:50%}.K4SlIkA74LNQHY_V2qO1 .KYYkk3_wS1HB2sSAXAdZ .TPXJljmxAyskztuf8vxM{color:var(--color-neutrals-coal)}`, "",{"version":3,"sources":["webpack://./src/sections/market-overview-section/market-features-table/market-features-table-header.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEF,4CACE,oDAAA,CACA,kBAAA,CACA,eAAA,CACA,SAAA,CAEA,kEACE,gCAAA","sourcesContent":[".tableHeaderRow {\n  display: flex;\n}\n.tableHeader .tableHeaderCell {\n  border-bottom: 1px solid var(--color-neutrals-silver);\n  padding-bottom: 8px;\n  text-align: left;\n  width: 50%;\n\n  & .tableHeaderTitle {\n    color: var(--color-neutrals-coal);\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderRow": `L_SDTp0zWDAjJcwArOk9`,
	"tableHeader": `K4SlIkA74LNQHY_V2qO1`,
	"tableHeaderCell": `KYYkk3_wS1HB2sSAXAdZ`,
	"tableHeaderTitle": `TPXJljmxAyskztuf8vxM`
};
export default ___CSS_LOADER_EXPORT___;
