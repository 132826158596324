import classNames from "classnames";
import { FreeTrialRole, useUserState } from "gx-npm-lib";
import {
  FreeTrialInitCreateModalComponent,
  ButtonLoader,
  MiniButton,
  SnackbarBanner,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { updateStateSubject } from "gx-npm-messenger-util";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { MarketProfileAppContext } from "../../../app.context";
import PreviewSVG from "../../../assets/images/preview.svg";
import DefaultPreviewSVG from "../../../assets/images/default-blue-preview.svg";
import { createInitiativeFromTemplateV2 } from "../../../lib/requests.lib";
import { HeaderPosition } from "../header-v3.constants";
import styles from "./header-action-buttons.styles.module.scss";

type HeaderActionButtonsProps = { headerPosition?: HeaderPosition; isDisabled?: boolean };
const HeaderActionButtonsComponent = ({
  headerPosition = HeaderPosition.STATIC_TOP,
  isDisabled = false,
}: HeaderActionButtonsProps) => {
  const { t } = useTranslation();
  const { marketId = "" } = useParams();
  const [isError, setIsError] = useState(false);
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] = useState(false);
  const { isProcessing, name, userInitCount, setIsProcessing } = useContext(MarketProfileAppContext);
  const { freeTrialRole, isEntitled } = useUserState();
  const isTrialUser = freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT;

  const handleClickPreviewTemplate = () => {
    const storedData = JSON.parse(sessionStorage.getItem("template_hub_session") || "{}");

    updateStateSubject("TEMPLATE_PREVIEW", {
      canUseTemplate: isEntitled,
      category: storedData.categoryName || "",
      categoryId: storedData.categoryId || 0,
      display: true,
      isSearchData: storedData.isSearchData || false,
      searchResultsCount: storedData.searchResultsCount || 0,
      searchTerm: storedData.searchTerm || "",
      templateId: Number(marketId),
      templateName: name,
      userInitCount,
    });
    singleSpa.navigateToUrl(`/s/market/${marketId}/preview`);
  };

  const handleCreateError = () => {
    setIsProcessing(false);
    setIsError(true);
  };

  const handleClickStartEval = async () => {
    if (isTrialUser) {
      setIsFreeTrialModalOpen(true);
    } else {
      setIsProcessing(true);
      await createInitiativeFromTemplateV2({ templateId: Number(marketId), templateName: name }, handleCreateError);
    }
  };

  const handleCloseFreeTrialModal = () => {
    setIsFreeTrialModalOpen(false);
  };

  const handleConfirmFreeTrialModal = async () => {
    setIsProcessing(true);
    await createInitiativeFromTemplateV2({ templateId: Number(marketId), templateName: name }, handleCreateError);
  };

  let startEvalBtnClass = "";
  if (headerPosition === HeaderPosition.FLOATING_SCROLL) {
    startEvalBtnClass = "btn-secondary";
  } else if (headerPosition === HeaderPosition.STATIC_TOP) {
    startEvalBtnClass = "secondary-dark-theme-btn";
  }
  return (
    <Fragment>
      <div className={styles.headerActionsContainer}>
        <MiniButton
          disabled={isDisabled}
          onClick={handleClickPreviewTemplate}
          rootClassName={classNames(styles.previewBtn, "gx-preview-btn")}
          variant={headerPosition === HeaderPosition.STATIC_TOP ? "darkTheme" : ""}
        >
          {headerPosition === HeaderPosition.FLOATING_SCROLL && <img alt="" src={DefaultPreviewSVG} />}
          {headerPosition === HeaderPosition.STATIC_TOP && <img alt="" src={PreviewSVG} />}
          <TypographyComponent styling={"span"} boldness={"medium"} rootClassName={styles.previewBtnText}>
            {t("Preview Template")}
          </TypographyComponent>
        </MiniButton>
        <TooltipV2
          deactivate={isEntitled || (isTrialUser && userInitCount < 3)}
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
          title={t(
            "Your account does not have permission to create initiatives. Please contact your Gartner Account Manager for assistance."
          )}
        >
          <div>
            {!isDisabled && (
              <ButtonLoader
                btnClass={startEvalBtnClass}
                btnRootClassName="gx-start-eval-from-market-btn"
                disabled={isDisabled || (!isEntitled && (!isTrialUser || userInitCount >= 3))}
                isLoading={isProcessing}
                onClick={handleClickStartEval}
              >
                {t("Start Evaluation")}
              </ButtonLoader>
            )}
          </div>
        </TooltipV2>
      </div>
      <SnackbarBanner
        isOpen={isError}
        message={t("Something went wrong. Please try again.")}
        setIsOpen={() => setIsError(false)}
        type="ERROR"
      />
      <FreeTrialInitCreateModalComponent
        currentInits={userInitCount}
        isOpen={isFreeTrialModalOpen}
        isProcessing={isProcessing}
        onClickClose={handleCloseFreeTrialModal}
        onClickConfirm={handleConfirmFreeTrialModal}
      />
    </Fragment>
  );
};

export default HeaderActionButtonsComponent;
