import { createContext, ReactNode, useState } from "react";
import { ScoredProduct, WeightedPriority, WeightedPriorityDrawerContextValue } from "./weighted-priority-drawer.types";

const WeightedPriorityDrawerContext = createContext<WeightedPriorityDrawerContextValue>(
  {} as WeightedPriorityDrawerContextValue
);

const WeightedPriorityDrawerContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [hasUpdateError, setHasUpdateError] = useState<boolean>(false);
  const [isFadeInTriggered, setIsFadeInTriggered] = useState<boolean>(true);
  const [isFooterInScrollingState, setIsFooterInScrollingState] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scoredProducts, setScoredProducts] = useState<ScoredProduct[]>([]);
  const [updateRankUrl, setUpdateRankUrl] = useState<string>("");
  const [weightedPriorities, setWeightedPriorities] = useState<WeightedPriority[]>([]);
  const contextValue = {
    hasUpdateError,
    isFadeInTriggered,
    isFooterInScrollingState,
    isLoading,
    updateRankUrl,
    weightedPriorities,
    scoredProducts,
    setHasUpdateError,
    setIsFadeInTriggered,
    setIsFooterInScrollingState,
    setIsLoading,
    setUpdateRankUrl,
    setWeightedPriorities,
    setScoredProducts,
  };

  return (
    <WeightedPriorityDrawerContext.Provider value={contextValue}>
      {props.children}
    </WeightedPriorityDrawerContext.Provider>
  );
};

export { WeightedPriorityDrawerContext, WeightedPriorityDrawerContextProvider };
