import classNames from "classnames";
import { ProductLinkComponent } from "gx-npm-ui";
import { Fragment, useContext } from "react";
import { MarketProfileAppContext } from "../../../app.context";
import MagicQuadrantsCell from "./cells/magic-quadrant-cell/magic-quadrants-cell.component";
import PeerInsightsCell from "./cells/peer-insights-cell/peer-insights-cell.component";
import VendorFitCell from "./cells/vendor-fit-cell/vendor-fit-cell.component";
import ProposalReviewBadgeComponent from "./proposal-review-badge/proposal-review-badge.component";
import styles from "./product-row.styles.module.scss";

type ProductRowProps = {
  id: string;
  index: number;
  imageLoc: string;
  mqPosition: string;
  name: string;
  peerRating: number;
  peerReviewCount: number;
  priorityScore: number;
  isEligiblePR: boolean;
};
const ProductRow = ({
  id = "",
  index = -1,
  imageLoc = "",
  mqPosition = "",
  name = "",
  peerRating = 0,
  peerReviewCount = 0,
  priorityScore = 0,
  isEligiblePR = false,
}: ProductRowProps) => {
  const { hasSelectedPriorities, gcomMqResId, templateId } = useContext(MarketProfileAppContext);
  const handleClick = () => {
    const isTopThreePrioritizedProduct = hasSelectedPriorities && [0, 1, 2].includes(index);
    if (isTopThreePrioritizedProduct && window.FS) {
      window.FS.event("top three prioritized product profile view", { templateId, productId: id, productName: name });
    }
  };

  return (
    <Fragment>
      <li
        className={classNames(
          styles.root,
          "gx-product-list-item",
          gcomMqResId !== -1 && styles.hasMqInfo,
          hasSelectedPriorities && styles.hasVendorFit
        )}
      >
        {isEligiblePR && <ProposalReviewBadgeComponent />}
        <ProductLinkComponent
          href={`/s/market/${templateId}/product/${id}`}
          imageLoc={imageLoc}
          logoSize="32px"
          name={name}
          onClick={handleClick}
          rootClassName="gx-vendor-logo-and-name-button"
          textBoldness={"medium"}
          textType={"p2"}
        />
        {gcomMqResId !== -1 && <MagicQuadrantsCell mqPosition={mqPosition} />}
        <PeerInsightsCell rating={peerRating} reviewCount={peerReviewCount} />
        {hasSelectedPriorities && <VendorFitCell name={name} priorityScore={priorityScore} />}
        <div className={styles.endPadding} />
      </li>
    </Fragment>
  );
};

export default ProductRow;
