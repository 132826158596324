import classNames from "classnames";
import { useUserState, useCaptureEventsV2 } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../app.constants";
import { MarketProfileAppContext } from "../../app.context";
import MarketFeaturesTableComponent from "./market-features-table/market-features-table.component";
import styles from "./market-overview-section.styles.module.scss";

const MarketOverviewSectionComponent = () => {
  const { t } = useTranslation();
  const { hasUserState } = useUserState();
  const { description, gcomCcDocCd, gcomCcResId, isLoading, templateId, name } = useContext(MarketProfileAppContext);
  const hasTableView = gcomCcDocCd > 0;
  const captureEvents = useCaptureEventsV2();

  const handlePoweredByLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const eventType = ClientEvent.MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED;
    const metaData = { templateId: templateId.toString(), templateName: name };
    captureEvents([{ eventType, metaData }]);
    window.open(`https://www.gartner.com/document/${gcomCcResId}`, "_blank", "noopener");
  };

  return (
    <section className={classNames(styles.sectionContainer)}>
      <div className={styles.cardV2}>
        <div className={styles.aboutHeader}>
          <TypographyComponent
            rootClassName={styles.aboutParagraphV2}
            color={"carbon"}
            boldness={"medium"}
            styling={"p1"}
          >
            {t("About the market")}
          </TypographyComponent>

          {gcomCcResId !== -1 && (
            <div className={styles.poweredBy}>
              <TypographyComponent rootClassName={styles.poweredByText} color={"iron"} styling={"p5"}>
                {t("Powered by")}
              </TypographyComponent>
              <a
                className={styles.poweredByLink}
                href={`https://www.gartner.com/document/${gcomCcResId}`}
                onClick={handlePoweredByLinkClick}
                rel="noopener"
                target="_blank"
              >
                <TypographyComponent
                  rootClassName={styles.poweredByLinkText}
                  color={"defaultCta"}
                  boldness={"medium"}
                  styling={"p5"}
                >
                  {t("Gartner Critical Capabilities")}
                </TypographyComponent>
              </a>
            </div>
          )}
        </div>
        <div
          className={classNames(
            hasTableView ? styles.detailsContainer : styles.detailsContainerNoTable,
            styles.detailsContainer
          )}
        >
          <TypographyComponent
            rootClassName={classNames(
              hasTableView ? styles.marketDescription : styles.marketDescriptionNoTable,
              styles.marketDescription
            )}
            styling={"p3"}
          >
            {description}
          </TypographyComponent>

          <div className={styles.featuresDisplayContainer}>
            {hasTableView && hasUserState && !isLoading && <MarketFeaturesTableComponent />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketOverviewSectionComponent;
