// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IROf4wU23BXXjdbbLEgy{background-color:rgba(0,0,169,.5);color:var(--color-white);border:1px solid var(--color-neutrals-quartz);width:295px;height:40px;display:flex;align-items:center;border-radius:60px;padding-left:16px;gap:10px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/research-slide-in-header-logo.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iCAAA,CACA,wBAAA,CACA,6CAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,QAAA","sourcesContent":[".researchSlideInHeaderLogoStyles {\n  background-color: rgba(0, 0, 169, 0.5);\n  color: var(--color-white);\n  border: 1px solid var(--color-neutrals-quartz);\n  width: 295px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  border-radius: 60px;\n  padding-left: 16px;\n  gap: 10px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"researchSlideInHeaderLogoStyles": `IROf4wU23BXXjdbbLEgy`
};
export default ___CSS_LOADER_EXPORT___;
