// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y4Vp83GT9rHb3Cuvfn_r{width:100%;margin-top:40px;border-bottom:1px solid var(--color-neutrals-silver);background-color:var(--color-white);border-top-left-radius:8px;border-top-right-radius:8px;display:grid;align-items:center;grid-template-columns:60% 1fr;white-space:nowrap}.y4Vp83GT9rHb3Cuvfn_r.HSmO9VUv2xGJ7f49czCE{grid-template-columns:60% 1fr 1fr}.y4Vp83GT9rHb3Cuvfn_r.HSmO9VUv2xGJ7f49czCE.f4q3crumOV5Mt358Y4Qb{grid-template-columns:50% 1fr 1fr 1fr}.y4Vp83GT9rHb3Cuvfn_r p{color:var(--color-neutrals-coal);margin-bottom:7px}p.NjyQ_RtvRc8ucJnnypwT{display:inline-block;margin-left:auto;margin-right:24px;width:115px}p.IMYJA2AN1tuZasboQZzh{margin-left:auto;margin-right:55px}`, "",{"version":3,"sources":["webpack://./src/sections/market-products/market-products-header/market-products-header.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA,CACA,oDAAA,CACA,mCAAA,CACA,0BAAA,CACA,2BAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CAGF,2CACE,iCAAA,CAGF,gEACE,qCAAA,CAEF,wBACE,gCAAA,CACA,iBAAA,CAGF,uBACE,oBAAA,CACA,gBAAA,CACA,iBAAA,CACA,WAAA,CAGF,uBACE,gBAAA,CACA,iBAAA","sourcesContent":[".headerRoot {\n  width: 100%;\n  margin-top: 40px;\n  border-bottom: 1px solid var(--color-neutrals-silver);\n  background-color: var(--color-white);\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n  display: grid;\n  align-items: center;\n  grid-template-columns: 60% 1fr;\n  white-space: nowrap;\n}\n\n.headerRoot.hasMqInfo {\n  grid-template-columns: 60% 1fr 1fr;\n}\n\n.headerRoot.hasMqInfo.hasVendorFit {\n  grid-template-columns: 50% 1fr 1fr 1fr;\n}\n.headerRoot p  {\n  color: var(--color-neutrals-coal);\n  margin-bottom: 7px;\n}\n\np.peerInsightsTitle {\n  display: inline-block;\n  margin-left: auto;\n  margin-right: 24px;\n  width: 115px;\n}\n\np.vendorFitTitle {\n  margin-left: auto;\n  margin-right: 55px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerRoot": `y4Vp83GT9rHb3Cuvfn_r`,
	"hasMqInfo": `HSmO9VUv2xGJ7f49czCE`,
	"hasVendorFit": `f4q3crumOV5Mt358Y4Qb`,
	"peerInsightsTitle": `NjyQ_RtvRc8ucJnnypwT`,
	"vendorFitTitle": `IMYJA2AN1tuZasboQZzh`
};
export default ___CSS_LOADER_EXPORT___;
