// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LU1xj_CBnCNJhJ6szSgf{align-items:center;background:var(--color-status-light-lemon);display:flex;height:48px;width:100%}.LU1xj_CBnCNJhJ6szSgf .Pv2I6X4GMeZ_LG37yIFr{padding:0 8px 0 16px}.LU1xj_CBnCNJhJ6szSgf .ZnhvHnJ4NCRlHmBHbMLr.oIYtYSj8AeTwAhAveRnS{margin:0 16px 0 auto;z-index:1150}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-banner/free-trial-banner.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,0CAAA,CACA,YAAA,CACA,WAAA,CACA,UAAA,CAEA,4CACE,oBAAA,CAGF,iEACE,oBAAA,CACA,YAAA","sourcesContent":[".bannerWrapper {\n  align-items: center;\n  background: var(--color-status-light-lemon);\n  display: flex;\n  height: 48px;\n  width: 100%;\n\n  & .iconContainer {\n    padding: 0 8px 0 16px;\n  }\n\n  & .moreInfoButton.additionalSpecificity {\n    margin: 0 16px 0 auto;\n    z-index: 1150;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerWrapper": `LU1xj_CBnCNJhJ6szSgf`,
	"iconContainer": `Pv2I6X4GMeZ_LG37yIFr`,
	"moreInfoButton": `ZnhvHnJ4NCRlHmBHbMLr`,
	"additionalSpecificity": `oIYtYSj8AeTwAhAveRnS`
};
export default ___CSS_LOADER_EXPORT___;
